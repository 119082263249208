<template>
  <grid-select
    v-model="selectedValue" 
    :label="label" 
    :defaultLabel="defaultLabel"
    :valueField="valueField"
    :labelField="labelField"
    :placeholder="placeholder"
    :size="size" 
    :searchItems="searchItems"
    :gridItems="gridItems"
    :getDataList="listGbpxTeacher"
    @change="handleChange"
  ></grid-select>
</template>

<script>
import { listGbpxTeacher } from "@/api/gbpx/gbpx_teacher";

export default {
  name: 'teacher-select',
  props: {
    value: {
      type: [String, Number],
      default: () => { return null }
    },
    label: {
      type: String,
      default: () => { return null }
    },
    defaultLabel: {
      type: String,
      default: () => { return null }
    },
    valueField: {
      type: String,
      default: () => { return "id" }
    },
    labelField: {
      type: String,
      default: () => { return "name" }
    },
    placeholder: {
      type: String,
      default: () => { return '请选择' }
    },
    size: {
      type: String,
      default: () => { return 'medium' }
    }
  },
  data() {
    return {
      listGbpxTeacher: listGbpxTeacher,
      selectedValue: this.value,
      searchItems: [{field: 'name', title: '姓名'}],
      gridItems: [{field: 'name', title: '姓名'}, {field: 'remark', title: '简介'}]
    }
  },
  created() {
  },
  methods: {
    handleChange(e1, e2) {
      this.$emit('input', e1)
      this.$emit('change', e1, e2)
    },
  },
  watch: {
    value(val) {
      this.selectedValue = val
    }
  }
}
</script>
