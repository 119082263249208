/**
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
 export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
      return null
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
      date = time
    } else {
      if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
        time = parseInt(time)
      }
      if (typeof time === 'number' && time.toString().length === 10) {
        time = time * 1000
      }
      date = new Date(time)
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay(),
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key]
      if (key === 'a') {
        return ['日', '一', '二', '三', '四', '五', '六'][value]
      }
      if (result.length > 0 && value < 10) {
        value = '0' + value
      }
      return value || 0
    })
    return time_str
  }
  
  /**
   * @description 格式化时间
   * @param time
   * @param option
   * @returns {string}
   */
  export function formatTime(time, option) {
    if (('' + time).length === 10) {
      time = parseInt(time) * 1000
    } else {
      time = +time
    }
    const d = new Date(time)
    const now = Date.now()
  
    const diff = (now - d) / 1000
  
    if (diff < 30) {
      return '刚刚'
    } else if (diff < 3600) {
      // less 1 hour
      return Math.ceil(diff / 60) + '分钟前'
    } else if (diff < 3600 * 24) {
      return Math.ceil(diff / 3600) + '小时前'
    } else if (diff < 3600 * 24 * 2) {
      return '1天前'
    }
    if (option) {
      return parseTime(time, option)
    } else {
      return (
        d.getMonth() +
        1 +
        '月' +
        d.getDate() +
        '日' +
        d.getHours() +
        '时' +
        d.getMinutes() +
        '分'
      )
    }
  }
  
  /**
   * @description 将url请求参数转为json格式
   * @param url
   * @returns {{}|any}
   */
  export function paramObj(url) {
    const search = url.split('?')[1]
    if (!search) {
      return {}
    }
    return JSON.parse(
      '{"' +
        decodeURIComponent(search)
          .replace(/"/g, '\\"')
          .replace(/&/g, '","')
          .replace(/=/g, '":"')
          .replace(/\+/g, ' ') +
        '"}'
    )
  }
  
  /**
   * @description 父子关系的数组转换成树形结构数据
   * @param data
   * @returns {*}
   */
  export function translateDataToTree(data) {
    const parent = data.filter(
      (value) => value.parentId === 'undefined' || value.parentId == null
    )
    const children = data.filter(
      (value) => value.parentId !== 'undefined' && value.parentId != null
    )
    const translator = (parent, children) => {
      parent.forEach((parent) => {
        children.forEach((current, index) => {
          if (current.parentId === parent.id) {
            const temp = JSON.parse(JSON.stringify(children))
            temp.splice(index, 1)
            translator([current], temp)
            typeof parent.children !== 'undefined'
              ? parent.children.push(current)
              : (parent.children = [current])
          }
        })
      })
    }
    translator(parent, children)
    return parent
  }
  
  /**
   * @description 树形结构数据转换成父子关系的数组
   * @param data
   * @returns {[]}
   */
  export function translateTreeToData(data) {
    const result = []
    data.forEach((item) => {
      const loop = (data) => {
        result.push({
          id: data.id,
          name: data.name,
          parentId: data.parentId,
        })
        const child = data.children
        if (child) {
          for (let i = 0; i < child.length; i++) {
            loop(child[i])
          }
        }
      }
      loop(item)
    })
    return result
  }
  
  /**
   * @description 10位时间戳转换
   * @param time
   * @returns {string}
   */
  export function tenBitTimestamp(time) {
    const date = new Date(time * 1000)
    const y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? '' + m : m
    let d = date.getDate()
    d = d < 10 ? '' + d : d
    let h = date.getHours()
    h = h < 10 ? '0' + h : h
    let minute = date.getMinutes()
    let second = date.getSeconds()
    minute = minute < 10 ? '0' + minute : minute
    second = second < 10 ? '0' + second : second
    return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
  }
  
  /**
   * @description 13位时间戳转换
   * @param time
   * @returns {string}
   */
  export function thirteenBitTimestamp(time) {
    const date = new Date(time / 1)
    const y = date.getFullYear()
    let m = date.getMonth() + 1
    m = m < 10 ? '' + m : m
    let d = date.getDate()
    d = d < 10 ? '' + d : d
    let h = date.getHours()
    h = h < 10 ? '0' + h : h
    let minute = date.getMinutes()
    let second = date.getSeconds()
    minute = minute < 10 ? '0' + minute : minute
    second = second < 10 ? '0' + second : second
    return y + '年' + m + '月' + d + '日 ' + h + ':' + minute + ':' + second //组合
  }
  
  /**
   * @description 获取随机id
   * @param length
   * @returns {string}
   */
  export function uuid(length = 32) {
    const num = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
    let str = ''
    for (let i = 0; i < length; i++) {
      str += num.charAt(Math.floor(Math.random() * num.length))
    }
    return str
  }
  
  /**
   * @description m到n的随机数
   * @param m
   * @param n
   * @returns {number}
   */
  export function random(m, n) {
    return Math.floor(Math.random() * (m - n) + n)
  }
  
  /**
   * @description addEventListener
   * @type {function(...[*]=)}
   */
  export const on = (function () {
    return function (element, event, handler, useCapture = false) {
      if (element && event && handler) {
        element.addEventListener(event, handler, useCapture)
      }
    }
  })()
  
  /**
   * @description removeEventListener
   * @type {function(...[*]=)}
   */
  export const off = (function () {
    return function (element, event, handler, useCapture = false) {
      if (element && event) {
        element.removeEventListener(event, handler, useCapture)
      }
    }
  })()

  /**
 * 防抖函数
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last)
    } else {
      timeout = null
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args)
        if (!timeout) context = args = null
      }
    }
  }

  return function(...args) {
    context = this
    timestamp = +new Date()
    const callNow = immediate && !timeout
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait)
    if (callNow) {
      result = func.apply(context, args)
      context = args = null
    }

    return result
  }
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */
export function deepClone(source) {
  if (!source && typeof source !== 'object') {
    throw new Error('error arguments', 'deepClone')
  }
  const targetObj = source.constructor === Array ? [] : {}
  Object.keys(source).forEach(keys => {
    if (source[keys] && typeof source[keys] === 'object') {
      targetObj[keys] = deepClone(source[keys])
    } else {
      targetObj[keys] = source[keys]
    }
  })
  return targetObj
}


/**
 * 计算后缀表达式的值
 * @param {Array} rpnExps - 后缀表达式
 */
export const calcRPN = rpnExps => {
  rpnExps = rpnExps.concat()
  const calc = ( x, y, type ) => {
    let a1 = Number( x ), a2 = Number( y )
    switch ( type ) {
      case '+': return a1 + a2;
      case '-': return a1 - a2;
      case '×': return a1 * a2;
      case '÷': return a1 / a2;
    }
  }
  for ( let i = 2; i < rpnExps.length; i++ ) {
    if ( '+-×÷'.includes( rpnExps[i] ) ) {
      let val = calc( rpnExps[i - 2], rpnExps[i - 1], rpnExps[i] )
      rpnExps.splice( i - 2, 3, val )
      i = i - 2
    }
  }
  return rpnExps[0]
}


/**
 * 金额转中文
 * 思路：
 *                             个
 *      十     百      千       万
 *      十万   百万    千万      亿
 *      十亿   百亿    千亿      万亿
 *
 *                              0
 *      1      2       3        4
 *      5      6       7        8
 *      9      10      11       12
 *
 * 计算步骤
 * 1. 获取当前数值大小
 * 2. 排除个位后 数值按个，十，百，千有规律的重复 所以计算其和4的余数 pos % 4
 * 3. 补充最大单位，例如上面第三，四行的万和亿
 *    pos = 0 ~ 3 没有最大单位
 *    pos = 4 ~ 7 最大单位是万
 *    pos = 8 ~ 11 最大单位是亿
 *    pos / 4 的整数就是最大单位
 */
export function getAmountChinese ( val ) {
  const amount = +val
  if ( Number.isNaN( amount ) || amount < 0 ) return ''
  const NUMBER = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖']
  const N_UNIT1 = ['', '拾', '佰', '仟']
  const N_UNIT2 = ['', '万', '亿', '万亿']
  const D_UNIT = ['角', '分', '厘', '毫']
  let [integer, decimal] = amount.toString().split( '.' )
  if ( integer && integer.length > 12 ) return '金额过大无法计算'
  let res = ''
  // 整数部分
  if ( integer ) {
    for ( let i = 0, len = integer.length; i < len; i++ ) {
      const num = integer.charAt( i );
      const isZero = num === '0';
      const pos = len - i - 1; // 排除个位后 所处的索引位置
      const isMaxUniPos = pos % 4 === 0;
      const isZeroNext = integer.charAt( i + 1 ) === '0';
      if ( !(isZero && (isZeroNext || isMaxUniPos))) { // 当前位 等于 0 且下一位也等于 0 则可跳过计算
        res += NUMBER[num];
        if (!isZero) res += N_UNIT1[pos % 4];
      }
      if(isMaxUniPos) {
        res += N_UNIT2[Math.floor( pos / 4 )];
      };
    }
    res += '圆'
  }
  // 小数部分
  if ( parseInt( decimal ) ) {
    const loopCount = Math.min(decimal.length, 4);
    for ( let i = 0; i < loopCount; i++ ) {
      const num = decimal.charAt( i )
      if ( num !== '0' ) res += NUMBER[num] + D_UNIT[i];
    }
  } else {
    res += '整'
  }
  return res
}

/**
 * 将用户输入的连续单个数字合并为一个数
 * @param {Array} expressions - 记录计算表达式的数组
 * @returns {Array} 新的数组
 */
export const mergeNumberOfExps = expressions => {
  const res = []
  const isNumChar = n => /^[\d|\.]$/.test( n )
  for ( let i = 0; i < expressions.length; i++ ) {
    if ( i > 0 && isNumChar( expressions[i - 1] ) && isNumChar( expressions[i] ) ) {
      res[res.length - 1] += expressions[i]
      continue
    }
    res.push( expressions[i] )
  }
  return res
}

/**
 * 中缀转后缀（逆波兰 Reverse Polish Notation）
 * @param {Array} exps - 中缀表达式数组
 */
export const toRPN = exps => {
  const s1 = [] // 符号栈
  const s2 = [] // 输出栈
  const getTopVal = ( stack ) => stack.length > 0 ? stack[stack.length - 1] : null
  const levelCompare = ( c1, c2 ) => {
    const getIndex = c => ['+-', '×÷', '()'].findIndex( t => t.includes( c ) )
    return getIndex( c1 ) - getIndex( c2 )
  }
  exps.forEach( t => {
    if ( typeof t === 'string' && Number.isNaN( Number( t ) ) ) { // 是符号
      if ( t === '(' ) {
        s1.push( t )
      } else if ( t === ')' ) {
        let popVal
        do {
          popVal = s1.pop()
          popVal !== '(' && s2.push( popVal )
        } while ( s1.length && popVal !== '(' )
      } else {
        let topVal = getTopVal( s1 )
        if ( !topVal ) { // s1 为空 直接push
          s1.push( t )
        } else {
          while ( topVal && topVal !== '(' && levelCompare( topVal, t ) >= 0 ) { // 优先级 >= t 弹出到s2
            s2.push( s1.pop() )
            topVal = getTopVal( s1 )
          }
          s1.push( t )
        }
      }
      return
    }
    s2.push( t ) // 数字直接入栈
  } )
  while ( s1.length ) {
    s2.push( s1.pop() )
  }
  return s2
}

/**
 * 校验表达式是否符合计算法则
 * @param {Array} expressions - 合并数字后的表达式数组
 * @returns {Boolean}
 */
export const validExp = ( expressions, mergeNum = true ) => {
  const temp = mergeNum ? mergeNumberOfExps( expressions ) : expressions
  const arr = temp.filter( t => !'()'.includes( t ) )
  // 去括号后 length应该为奇数  并且第一个字符和最后一个字符应该为数字而非计算符号
  if ( temp.length % 2 === 0 || arr.length % 2 === 0 || Number.isNaN( +arr[0] ) || Number.isNaN( +arr[arr.length - 1] ) ) {
    return false
  }
  for ( let i = 0; i < arr.length - 1; i += 2 ) {
    if ( typeof ( +arr[i] ) !== 'number' || !Number.isNaN( +arr[i + 1] ) ) return false
  }
  return true
}

  