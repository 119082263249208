<template>
  <el-submenu
    ref="subMenu"
    :index="handlePath(item.path)"
    :popper-append-to-body="false"
  >
    <template slot="title">
      <!-- <i v-if="item.meta && item.meta.icon" :class="['el-icon pr-3', `${item.meta.icon}`]" ></i> -->
      <icon-item :icon="item.meta.icon||(item.meta&&item.meta.icon)" :title="item.title||item.meta.title" />
      <!-- <span>{{ item.meta.title }}</span> -->
    </template>
    <slot />
  </el-submenu>
</template>

<script>
  import { isExternal } from '@/utils/validate'
  import path from 'path'
  import IconItem from './Item'
  export default {
    name: 'Submenu',
    components: { IconItem},
    props: {
      routeChildren: {
        type: Object,
        default() {
          return null
        },
      },
      item: {
        type: Object,
        default() {
          return null
        },
      },
      fullPath: {
        type: String,
        default: '',
      },
    },
    created() {
    },
    methods: {
      handlePath(routePath) {
        if (isExternal(routePath)) {
          return routePath
        }
        if (isExternal(this.fullPath)) {
          return this.fullPath
        }
        return path.resolve(this.fullPath, routePath)
      },
    },
  }
</script>
