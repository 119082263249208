<template>
    <form-item-wrapper
        :designer="designer"
        :field="field"
        :rules="rules"
        :design-state="designState"
        :parent-widget="parentWidget"
        :parent-list="parentList"
        :index-of-parent-list="indexOfParentList"
        :sub-form-row-index="subFormRowIndex"
        :sub-form-col-index="subFormColIndex"
        :sub-form-row-id="subFormRowId"
    >
        <el-input
            ref="fieldEditor"
            v-model="fieldModel"
            v-show="!isReadMode"
            :disabled="field.options.disabled"
            :readonly="field.options.readonly"
            class="hide-spin-button"
            :type="inputType"
            :show-password="field.options.showPassword"
            :placeholder="field.options.placeholder"
            :clearable="field.options.clearable"
            :minlength="field.options.minLength"
            :maxlength="field.options.maxLength"
            :show-word-limit="field.options.showWordLimit"
            :prefix-icon="field.options.prefixIcon"
            :suffix-icon="field.options.suffixIcon"
            @focus="handleFocusCustomEvent"
            @blur="handleBlurCustomEvent"
            @input="handleInputCustomEvent"
            @change="handleChangeEvent"
        >
            <el-button
                slot="append"
                v-if="field.options.appendButton"
                :disabled="
                    field.options.disabled || field.options.appendButtonDisabled
                "
                :class="field.options.buttonIcon"
                @click.native="emitAppendButtonClick"
            ></el-button>
        </el-input>
        <template v-if="isReadMode">
            <span class="readonly-mode-field">{{ fieldModel }}</span>
        </template>
    </form-item-wrapper>
</template>

<script>
import FormItemWrapper from "./form-item-wrapper";
import emitter from "@/vform/utils/emitter";
import i18n, { translate } from "@/vform/utils/i18n";
import fieldMixin from "@/vform/components/form-designer/form-widget/field-widget/fieldMixin";

export default {
    name: "input-widget",
    componentName: "FieldWidget", //必须固定为FieldWidget，用于接收父级组件的broadcast事件
    mixins: [emitter, fieldMixin, i18n],
    props: {
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designer: Object,

        designState: {
            type: Boolean,
            default: false,
        },

        subFormRowIndex: {
            /* 子表单组件行索引，从0开始计数 */ type: Number,
            default: -1,
        },
        subFormColIndex: {
            /* 子表单组件列索引，从0开始计数 */ type: Number,
            default: -1,
        },
        subFormRowId: {
            /* 子表单组件行Id，唯一id且不可变 */ type: String,
            default: "",
        },
    },
    components: {
        FormItemWrapper,
    },
    inject: ["refList", "globalOptionData", "globalModel"],

    data() {
        var validateLength = (rule, value, callback) => {
            if (value.length < this.field.options.minLength ) {
                callback(new Error(`输入不得小于${this.field.options.minLength}`));
            } else {
               
                callback();
            }
        };
        return {
            oldFieldValue: null, //field组件change之前的值
            fieldModel: null,
            rules: [],
        };
    },
    computed: {
        inputType() {
            if (this.field.options.type === "number") {
                return "text"; //当input的type设置为number时，如果输入非数字字符，则v-model拿到的值为空字符串，无法实现输入校验！故屏蔽之！！
            }

            return this.field.options.type;
        },
    },
    beforeCreate() {
        /* 这里不能访问方法和属性！！ */
    },

    created() {
        /* 注意：子组件mounted在父组件created之后、父组件mounted之前触发，故子组件mounted需要用到的prop
         需要在父组件created中初始化！！ */
        this.registerToRefList();
        this.initFieldModel();
        this.initEventHandler();
        this.buildFieldRules();

        this.handleOnCreated();
    },

    mounted() {
        this.handleOnMounted();
    },

    beforeDestroy() {
        this.unregisterFromRefList();
    },

    methods: {},
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/global.scss"; //* form-item-wrapper已引入，还需要重复引入吗？ *//
</style>
