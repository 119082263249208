<template>
    <div v-if="routerView" class="app-main-container">
        <transition mode="out-in" name="fade-transform">
            <keep-alive :include="cachedRoutes" :max="keepAliveMaxNum">
                <router-view :key="key" class="app-main-height" />
            </keep-alive>
        </transition>
        <!-- <router-view v-slot="{ Component, route }" class="app-main-height">
			<transition appear name="fade-transform" mode="out-in">
				<keep-alive :include="cachedRoutes" :max="keepAliveMaxNum">
					<component :is="Component" v-if="isRouterShow" :key="key" />
				</keep-alive>
			</transition>
		</router-view> -->
        <footer v-show="footerCopyright" class="footer-copyright">
            Copyright
            <i class="iconfont icon-banquan-kuai"></i>
            {{ title }} {{ fullYear }}
        </footer>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { copyright, footerCopyright, keepAliveMaxNum, title } from "@/config";

export default {
    name: "AppMain",
    data() {
        return {
            show: false,
            fullYear: new Date().getFullYear(),
            copyright,
            title,
            keepAliveMaxNum,
            routerView: true,
            footerCopyright,
            isRouterShow: "",
        };
    },
    computed: {
        ...mapGetters({
            visitedRoutes: "tabsBar/visitedRoutes",
            device: "settings/device",
        }),
        cachedRoutes() {
            const cachedRoutesArr = [];
            this.visitedRoutes &&
                this.visitedRoutes.forEach((item) => {
                    if (!item.meta.noKeepAlive) {
                        cachedRoutesArr.push(item.name);
                    }
                });

            // console.log(cachedRoutesArr,process.env.NODE_ENV);
            return process.env.NODE_ENV==='development'?[]: cachedRoutesArr
            // return cachedRoutesArr;
        },
        key() {
            return this.$route.path;
        },
    },
    watch: {
        $route: {
            handler(route) {
                if ("mobile" === this.device) this.foldSideBar();
            },
            immediate: true,
        },
    },
    created() {
        //重载所有路由
        this.$baseEventBus.$on("reload-router-view", () => {
            this.routerView = false;
            this.$nextTick(() => {
                this.routerView = true;
            });
        });
    },
    mounted() {},
    methods: {
        ...mapActions({
            foldSideBar: "settings/foldSideBar",
        }),
    },
};
</script>

<style lang="scss" scoped>
.app-main-container {
    position: relative;
    width: 100%;
    overflow: hidden;

    .qz-micro-keel {
        margin: $base-padding;
    }

    .app-main-height {
        min-height: $base-app-main-height;
    }

    .footer-copyright {
        min-height: 55px;
        line-height: 55px;
        color: rgba(0, 0, 0, 0.45);
        text-align: center;
        border-top: 1px dashed $base-border-color;
    }
}
</style>