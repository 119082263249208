import request from '@/utils/request'

export function listStuClasses(query) {
  return request({
    url: '/stu/stuclasses/list',
    method: 'get',
    params: query
  })
}

export function getStuClasses(id) {
  return request({
    url: '/stu/stuclasses/get?id=' + id,
    method: 'get'
  })
}

export function addStuClasses(data) {
  return request({
    url: '/stu/stuclasses/add',
    method: 'post',
    data: data
  })
}

export function editStuClasses(data) {
  return request({
    url: '/stu/stuclasses/edit',
    method: 'post',
    data: data
  })
}

export function delStuClasses(ids) {
  return request({
    url: '/stu/stuclasses/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function syncStuClasses() {
  return request({
    url: '/stu/stuclasses/sync',
    method: 'post'
  })
}
