import { render, staticRenderFns } from "./vf-drawer-item.vue?vue&type=template&id=daed1e12&scoped=true&"
import script from "./vf-drawer-item.vue?vue&type=script&lang=js&"
export * from "./vf-drawer-item.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daed1e12",
  null
  
)

export default component.exports