import request from '@/utils/request'

export function listOaTaskRecord(query) {
  return request({
    url: '/oataskrecord/list',
    method: 'get',
    params: query
  })
}

export function myListOaTaskRecord(query) {
  return request({
    url: '/oataskrecord/mylist',
    method: 'get',
    params: query
  })
}

export function approveListOaTaskRecord(query) {
  return request({
    url: '/oataskrecord/approvelist',
    method: 'get',
    params: query
  })
}

export function getOaTaskRecord(id) {
  return request({
    url: '/oataskrecord/get?id=' + id,
    method: 'get'
  })
}

export function addOaTaskRecord(data) {
  return request({
    url: '/oataskrecord/add',
    method: 'post',
    data: data
  })
}

export function editOaTaskRecord(data) {
  return request({
    url: '/oataskrecord/edit',
    method: 'post',
    data: data
  })
}

export function delOaTaskRecord(ids) {
  return request({
    url: '/oataskrecord/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function readOaTaskRecord(data) {
  return request({
    url: '/oataskrecord/read',
    method: 'post',
    data: data
  })
}

export function transferOaTaskRecord(data) {
  return request({
    url: '/oataskrecord/transfer',
    method: 'post',
    data: data
  })
}

export function submitOaTaskRecord(data) {
  return request({
    url: '/oataskrecord/submit',
    method: 'post',
    data: data
  })
}

export function rejectOaTaskRecord(data) {
  return request({
    url: '/oataskrecord/reject',
    method: 'post',
    data: data
  })
}

export function approveOaTaskRecord(data) {
  return request({
    url: '/oataskrecord/approve',
    method: 'post',
    data: data
  })
}