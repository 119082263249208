<template>
    <!-- <div class="back-to-main-app" @click="handelBackMainApp">
        <transition name="el-fade-in-linear">
            <el-link v-show="showText">返回智慧校园平台<i class="el-icon-d-arrow-right"></i> </el-link>
        </transition>
        <transition name="el-fade-in-linear">
            <i v-show="!showText" title="返回智慧校园平台" class="el-icon-s-home"></i>
        </transition>
    </div> -->
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: 'BackToMainApp',
    data() {
        return {
        }
    },
    computed: {
        ...mapGetters({
            layout: "settings/layout",
            device: "settings/device",
        }),
        showText() {
            let { layout, device } = this
            return layout !== 'horizontal' && device != 'mobile'
        }
    },
    methods: {
        handelBackMainApp() {
          window.location.href = '/';
        }
    }
}
</script>

<style lang="scss" scoped>
.back-to-main-app {
   margin-right: 35px;
}
.el-icon-s-home {
    font-size: 18px;
    cursor: pointer;
}
</style>