<template>
  <div :class="'logo-container-' + layout">
    <router-link to="/">
      <!-- 这里是logo变更的位置 -->
      <a>
        <!-- <span v-if="lightLogo" fit="contain" class="logo logo2"></span> -->
        <img :src="application.logo ||(siderBackgroundImg==='background2'?white_logo:logo) " alt="" class="index-logo">
        <!-- <span v-else class="logo logo1" fit="contain"></span> -->
      </a>
      <span class="title" :class="{ 'hidden-xs-only': layout === 'horizontal' }" :title="application.display_name || title">
        {{ application.display_name || title }}
      </span>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Logo',
  data() {
    return {
      title: process.env.VUE_APP_TITLE,
      subtitle: process.env.VUE_APP_SUB_TITLE,
      logo: process.env.VUE_APP_LOGO,
      white_logo:process.env.VUE_APP_WHITE_LOGO,
      
    }
  },
  computed: {
    ...mapGetters({
      layout: 'settings/layout',
      themeMode: 'settings/themeMode',
      siderBackgroundImg: 'settings/siderBackgroundImg',
      collapse: 'settings/collapse',
      application: 'application/application',
    }),
    lightLogo() {
     
      let { layout, themeMode, siderBackgroundImg, collapse } = this
      if ('dark' == themeMode) {
        return true
      } else if ((layout === 'vertical' || layout === 'comprehensive') && collapse) {
        return false
      } else if (siderBackgroundImg == 'background' && layout !== 'horizontal') {
        return true
      } else {
        return false
      }
    }
  },
  created(){
   
  },
}
</script>
<style lang="scss" scoped>
@mixin container {
  position: relative;
  height: $base-top-bar-height;
  overflow: hidden;
  line-height: $base-top-bar-height;
}

@mixin index-logo {
  display: inline-block;
  /** 304*400 */
  // width: 28px;
  // height: 36.8421px;
  color: $base-title-color;
  vertical-align: middle;
  width: 50px;

  &.logo2 {
    background: url('~@/assets/home/logo2.png');
    background-size: 100% 100%;
  }

  &.logo1 {
    background: url('~@/assets/home/logo1.png');
    background-size: 100% 100%;
  }
}

@mixin title {
  display: inline-block;
  overflow: hidden;
  font-size: 20px;
  line-height: 55px;
  color: var(--color-whitle);
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  margin-left: 5px;
  letter-spacing: 1px;
}

.logo-container-horizontal {
  @include container;

  .index-logo {
    @include index-logo;
    margin-right: 5px;
  }

  .title {
    @include title;
  }
}

.logo-container-vertical,
.logo-container-comprehensive {
  @include container;

  height: $base-logo-height;
  line-height: $base-logo-height;
  text-align: center;

  .index-logo {
    @include index-logo;
    margin-right: 5px;

  }

  .title {
    @include title;
    max-width: calc(#{$base-left-menu-width} - 60px);
    transition: width $base-transition-time;
  }
}

.logo-container-column {
  @include container;
  height: $base-left-column-height;
  line-height: $base-left-column-height;
  text-align: center;

  a {
    position: fixed;
    top: 0;
    display: block;
    width: $base-left-column-width;
    height: $base-left-column-height;
    margin: 0;
    background: var(--menu-background);
  }

  .index-logo {
    @include index-logo;
  }

  .title {
    @include title;
    width: calc(#{$base-left-menu-width} - #{$base-left-column-width});
    transition: width, display $base-transition-time;
    padding-right: 10px;
    padding-left: 10px;
    margin-left: $base-left-column-width !important;
    display: inline-block;
    overflow: hidden;
    font-size: 20px;
    line-height: 55px;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    color: $base-color-black;
  }
}
</style>
  