<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="open"
      width="1080px"
      append-to-body
    >
      <jf-form
        v-if="open"
        ref="form"
        :model="form"
        label-width="100px"
        :readonly="title == '查看'"
        :readonly-column="2"
      >
        <el-row>
          <el-col :span="12">
            <jf-form-item
              label="消息标题"
              prop="title"
              :span="2"
              :value="form.title"
            >
            </jf-form-item>
          </el-col>
          <el-col :span="24">
            <jf-form-item
              label="消息内容"
              prop="content"
              :span="2"
              :value="form.content"
            >
            </jf-form-item>
          </el-col>
          <el-col :span="24">
            <jf-form-item
              label="发送时间"
              prop="create_time"
              :span="2"
              :value="form.create_time"
            >
            </jf-form-item>
          </el-col>
        </el-row>
      </jf-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { readMessage } from "@/api/core/system/message";
import { EventBus } from "@/utils/eventBus.js";
export default {
  name: "message-dialogs",
  props: {},
  data() {
    return {
      title: "查看",
      open: false,
      form: {},
    };
  },
  methods: {
    showView(row) {
      let info = {
        title: row.title,
        content: row.content,
        create_time: this.parseTime(row.create_time, "{y}-{m}-{d} {h}:{i}:{s}"),
      };
      this.form = info;
      this.open = true;
      if (row.read_flag === "0") {
        readMessage(row.id).then((res) => {
          // this.msgSuccess("已读");
          EventBus.$emit("readMeg", 'readMessage');
        });
      }
    },
    cancel() {
      this.open = false;
    },
  },
};
</script>
