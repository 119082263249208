import request from '@/utils/request'

// 查询消息列表
export function listMessage(query) {
  return request({
    url: '/message/list',
    method: 'get',
    params: query
  })
}

// 我的消息列表
export function myMessage(query) {
  return request({
    url: '/message/mylist',
    method: 'get',
    params: query
  })
}

// 查询消息详细
export function getMessage(messageId) {
  return request({
    url: '/message/get?id=' + messageId,
    method: 'get'
  })
}

// 删除消息
export function delMessage(messageId) {
  return request({
    url: '/message/del',
    method: 'post',
    data:{ids:messageId}
  })
}

// 消息设置已读
export function readMessage(messageId) {
  return request({
    url: '/message/read',
    method: 'post',
    data:{id:messageId}
  })
}

// 查询消息模版列表
export function listMessageTemplate(query) {
  return request({
    url: '/messagetemplate/list',
    method: 'get',
    params: query
  })
}

// 查询消息模版详细
export function getMessageTemplate(id) {
  return request({
    url: '/messagetemplate/get?id=' + id,
    method: 'get'
  })
}

// 删除模版消息
export function delMessageTemplate(ids) {
  return request({
    url: '/messagetemplate/del',
    method: 'post',
    data:{ids:ids}
  })
}

export function addMessageTemplate(data) {
  return request({
    url: '/messagetemplate/add',
    method: 'post',
    data: data
  })
}

export function editMessageTemplate(data) {
  return request({
    url: '/messagetemplate/edit',
    method: 'post',
    data: data
  })
}