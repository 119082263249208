<template>
    <span v-if="themeBar">
        <icon-badge iconName="el-icon-setting" content="主题配置"  @click="handleOpenThemeBar"/>
    </span>
  </template>
  
  <script>
    import { mapActions, mapGetters } from 'vuex'
    export default {
      name: 'ThemeBar',
      data() {
        return {
        }
      },
      computed: {
        ...mapGetters({
          themeBar: 'settings/themeBar',
        }),
      },
      created() {
        
      },
      methods: {
        ...mapActions({
          changeThemeDrawer: 'settings/changeThemeDrawer'
        }),
        handleOpenThemeBar() {
          this.drawerOpen()
        },
        drawerOpen() {
          this.changeThemeDrawer(true)
        },
        drawerClose() {
          this.changeThemeDrawer(false)
        }
      },
    }
  </script>
  
  <style lang="scss" scoped>
    @mixin right-bar {
      position: fixed;
      right: 0;
      z-index: $base-z-index;
      width: 60px;
      min-height: 60px;
      text-align: center;
      cursor: pointer;
      background: $base-color-blue;
      border-radius: $base-border-radius;
  
      > div {
        padding-top: 10px;
        border-bottom: 0 !important;
  
        &:hover {
          opacity: 0.9;
        }
  
        & + div {
          border-top: 1px solid $base-color-white;
        }
  
        p {
          padding: 0;
          margin: 0;
          font-size: $base-font-size-small;
          line-height: 30px;
          color: $base-color-white;
        }
      }
    }
    .theme-icon{
        font-size: 18px;
        text-align: center;
        margin-right: 20px;
        color: $base-color-white;
    }
    
    
    .theme-bar-setting {
      @include right-bar;
  
      top: calc((100vh - 110px) / 2);
  
      ::v-deep {
        svg:not(:root).svg-inline--fa {
          display: block;
          margin-right: auto;
          margin-left: auto;
          color: $base-color-white;
        }
  
        .svg-icon {
          display: block;
          margin-right: auto;
          margin-left: auto;
          font-size: 20px;
          color: $base-color-white;
          fill: $base-color-white;
        }
      }
    }
  
    
  </style>
  