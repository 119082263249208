import request from '@/utils/request'

export function info() {
  return request({
    url: '/cache/info',
    method: 'get'
  })
}

export function clear() {
  return request({
    url: '/cache/clear',
    method: 'post'
  })
}

export function keys(cursor, match, count) {
  return request({
    url: '/cache/keys',
    method: 'get',
    params: {
      cursor: cursor,
      match: match,
      count: count
    }
  })
}

export function hkeys(key, cursor, match, count) {
  return request({
    url: '/cache/hkeys',
    method: 'get',
    params: {
      key: key,
      cursor: cursor,
      match: match,
      count: count
    }
  })
}

export function get(key) {
  return request({
    url: '/cache/get',
    method: 'get',
    params: {
      key: key
    }
  })
}

export function hget(key, field) {
  return request({
    url: '/cache/hget',
    method: 'get',
    params: {
      key: key,
      field: field
    }
  })
}

export function del(key) {
  return request({
    url: '/cache/del',
    method: 'post',
    data: {
      key: key
    }
  })
}

export function hdel(key, field) {
  return request({
    url: '/cache/hdel',
    method: 'post',
    data: {
      key: key,
      field: field
    }
  })
}

