import request from '@/utils/request'

export function listStuAssistant(query) {
  return request({
    url: '/stu/stuassistant/list',
    method: 'get',
    params: query
  })
}

export function getStuAssistant(id) {
  return request({
    url: '/stu/stuassistant/get?id=' + id,
    method: 'get'
  })
}

export function addStuAssistant(data) {
  return request({
    url: '/stu/stuassistant/add',
    method: 'post',
    data: data
  })
}

export function editStuAssistant(data) {
  return request({
    url: '/stu/stuassistant/edit',
    method: 'post',
    data: data
  })
}

export function delStuAssistant(ids) {
  return request({
    url: '/stu/stuassistant/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function bindStuAssistant(data) {
  return request({
    url: '/stu/stuassistant/bind',
    method: 'post',
    data: data
  })
}

export function syncAccount(query) {
  return request({
    url: '/stu/stuassistant/syncAccount',
    method: 'post',
    data: query
  })
}

export function syncAccountProgress() {
  return request({
    url: '/stu/stuassistant/syncAccountProgress',
    method: 'get'
  })
}

export function stuAssistantClassMajor(query) {
  return request({
    url: '/stu/stuassistant/classmajor',
    method: 'get',
    params: query
  })
}