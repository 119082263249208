<!-- 纵向侧边栏 -->
<template>
  <el-scrollbar class="side-bar-container" :class="{ 'is-collapse': collapse }">
    <logo />
    <el-menu :default-active="activeMenu" :collapse="collapse" :collapse-transition="false"
      :default-openeds="defaultOpens" :unique-opened="uniqueOpened" mode="vertical">
      <template v-for="route in main_routes">
        <side-bar-item :key="route.path" :full-path="route.path" :item="route" />
      </template>
    </el-menu>
  </el-scrollbar>
</template>
<script>
import variables from '@/styles/variables.scss'
import { mapGetters } from 'vuex'
import { defaultOopeneds, uniqueOpened } from '@/config'
import path from "path";
export default {
  name: 'SideBar',
  data() {
    return {
      uniqueOpened,
      // is_exist: false,
      mainRouteId: '',
      currentRoute: undefined,
      rootPath: '/',
      routeTopId: '',
      newMainRoutes: []
    }
  },
  computed: {
    ...mapGetters({
      collapse: 'settings/collapse',
      routes: 'routes/routes',
      mainRoutes: 'routes/mainRoutes'
    }),
    defaultOpens() {
      if (this.collapse) {
      }
      return defaultOopeneds
    },
    activeMenu() {
      const route = this.$route
      const { meta, path } = route
      if (meta.activeMenu) {
        return meta.activeMenu
      }
   
      return path
    },
    variables() {
      return variables
    },
    main_routes() {
      let { mainRoutes } = this
      if (!this.routeTopId) {
        return mainRoutes
      }
      for (let r in mainRoutes) {
        let proutes = mainRoutes[r];
        if (proutes.id == this.routeTopId) {
          return mainRoutes
        }
      }
      // mainRoutes.map(o => {
      //   if (o.path == '/app') {
      //     o.children.concat([this.rootRoute])
      //   }
      //   return o
      // })
      // 
      return mainRoutes.concat([this.rootRoute])
    },
  },
  watch: {
    $route() {
      this.currentRoute = undefined
      this.rootRoute = undefined
      this.rootPath = '/'
      this.routeTopId = 0
      const { path } = this.$route;
      if (path) {
        this.initRoute(path)
      }
    },
  },
  beforeMount() {
    const { path } = this.$route;
    if (path) {
      this.initRoute(path)
    }
  },
  methods: {
    initRoute(path) {
      let { routes } = this
      for (let key in routes) {
        let route = routes[key];
        if (route.children) {
          this.findRoutePath(route,
            path,
            route.children,
            route.path
          );
          if (this.currentRoute) {
            break;
          }
        }
      }
    },
    findRoutePath(rootRoute, route_path, routes, basePath = "/") {
      for (let key in routes) {
        let route = routes[key];
        if (route.menu_type == "1") {
          const tagPath = path.resolve(basePath, route.path);
          if (tagPath == route_path) {
            this.$store.dispatch("routes/setCurrentRoute", { current: this.currentRoute, root: this.rootRoute });
            this.currentRoute = route
            this.rootRoute = rootRoute;
            this.routeTopId = rootRoute.id
            return true;
          }
        }
        if (route.children) {
          this.findRoutePath(
            rootRoute,
            route_path,
            route.children,
            route.path
          );
        }
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@mixin active {
  &:hover {
    background-color: var(--menu-background-active);
    color: var(--menu-color-active);

    i {
      color: var(--menu-color-active);
    }
  }

  &.is-active {
    background-color: var(--menu-background-active);
    color: var(--menu-color-active);

    i {
      color: var(--menu-color-active);
    }
  }

}

.side-bar-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: $base-z-index;
  width: $base-left-menu-width;
  height: 100vh;
  overflow: hidden;
  background: var(--bg-color);
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  transition: width $base-transition-time;

  &.is-collapse {
    width: $base-left-menu-width-min;
    border-right: 0;

    ::v-deep {
      .el-menu {
        transition: width $base-transition-time;
      }

      .el-menu--collapse {
        border-right: 0;

        .el-submenu__title {
          overflow: visible;
        }

        .el-submenu__icon-arrow {
          right: 10px;
          margin-top: -3px;
        }
      }
    }

  }

  ::v-deep {
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }

    .el-menu {
      border: 0;
      background: rgba(255, 255, 255, 0);

      &.el-menu--popup {
        background: var(--menu-background);
      }

      .iconfont {
        padding-right: 3px;
        font-size: $base-font-size-default;
        color: var(--menu-color);
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
      }

      i {
        color: var(--menu-color);
      }
    }

    .el-menu-item,
    .el-submenu__title {
      height: 50px;
      overflow: hidden;
      line-height: 50px;
      text-overflow: ellipsis;
      white-space: nowrap;
      vertical-align: middle;
      color: var(--menu-color);
    }

    .el-menu-item,
    .el-submenu__title {
      @include active;
    }
  }
}
</style>
  