<template>
  <div>
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="open"
      width="800px"
      append-to-body
    >
      <jf-form
        v-if="open"
        ref="form"
        :model="form"
        :rules="rules"
        label-width="100px"
        :readonly="true"
        :readonly-column="1"
      >
        <template #attachments>
          <el-descriptions-item>
            <template slot="label"> 任务附件 </template>
            <file-upload-ex
              v-model="form.task.attachments"
              list-type="text"
              :readonly="true"
            />
          </el-descriptions-item>
        </template>
        <template #submit_content>
          <el-descriptions-item>
            <template slot="label"> 提交内容 </template>
            <el-input
              v-model="form.submit_content"
              placeholder="请输入提交内容"
              clearable
              type="textarea"
            />
          </el-descriptions-item>
        </template>
        <template #submit_files>
          <el-descriptions-item>
            <template slot="label"> 提交材料 </template>
            <file-upload-ex
              v-model="form.submit_files"
              list-type="text"
              :readonly="title != '提交'"
            />
          </el-descriptions-item>
        </template>
        <template #reject_reason>
          <el-descriptions-item>
            <template slot="label"> 拒绝原因 </template>
            <el-input
              v-model="form.reject_reason"
              placeholder="请输入拒绝原因"
              clearable
              type="textarea"
            />
          </el-descriptions-item>
        </template>
        <el-row>
          <el-col :span="12">
            <jf-form-item label="任务类型" prop="type">
              <dict-select
                v-model="form.task.type"
                placeholder=""
                type="oa_task_type"
              />
            </jf-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="任务名称" prop="task_title">
              <el-input v-model="form.task.title" placeholder="" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="任务内容" prop="task_content">
              <el-input
                v-model="form.task.content"
                placeholder=""
                type="textarea"
              />
            </el-form-item>
          </el-col>
          <el-col v-if="form.task.attachments" :span="12">
            <jf-form-item
              label="任务附件"
              prop="attachments"
              slots="attachments"
            >
              <file-upload-ex
                v-model="form.task.attachments"
                list-type="text"
              />
            </jf-form-item>
          </el-col>
          <el-col
            :span="12"
            v-hasPermi="[
              '/oa/oataskrecord/transfer',
              '/oa/oataskrecord/submit',
              '/oa/oataskrecord/reject',
            ]"
          >
            <el-form-item label="状态" prop="status">
              <dict-select
                v-model="form.status"
                placeholder="请选择状态"
                type="oa_task_record_status"
              />
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="title == '提交' || form.submit_status == '1'"
            v-hasPermi="['/oa/oataskrecord/submit']"
          >
            <jf-form-item
              v-if="title == '提交'"
              label="提交内容"
              prop="submit_content"
              slots="submit_content"
            >
              <el-input
                v-model="form.submit_content"
                placeholder="请输入提交内容"
                clearable
                type="textarea"
              />
            </jf-form-item>
            <jf-form-item v-else label="提交内容" :value="form.submit_content">
            </jf-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="title == '提交' || form.submit_status == '1'"
            v-hasPermi="['/oa/oataskrecord/submit']"
          >
            <jf-form-item
              label="提交材料"
              prop="submit_files"
              slots="submit_files"
            >
              <el-input
                v-model="form.submit_files"
                placeholder="请输入提交材料"
                clearable
              />
            </jf-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="title == '查看' && form.submit_status == '1'"
            v-hasPermi="['/oa/oataskrecord/submit']"
          >
            <el-form-item label="提交时间" prop="submit_time">
              <datetime-select
                v-model="form.submit_time"
                type="datetime"
                placeholder="请选择提交时间"
              />
            </el-form-item>
          </el-col>
          <el-col
            :span="12"
            v-if="form.submit_status == '1'"
            v-hasPermi="['/oa/oataskrecord/submit']"
          >
            <el-form-item label="分数" prop="score">
              <el-input-number
                v-model="form.score"
                placeholder="请输入分数"
                controls-position="right"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="截止时间" prop="submit_closing_date">
              <datetime-select
                v-model="form.task.submit_closing_date"
                type="datetime"
                placeholder="请选截止时间"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="阅读时间" prop="read_time">
              <datetime-select
                v-model="form.read_time"
                type="datetime"
                placeholder="请选择阅读时间"
              />
            </el-form-item>
          </el-col>
          <el-col v-if="title == '拒绝'" :span="12">
            <jf-form-item
              label="拒绝原因"
              prop="reject_reason"
              slots="reject_reason"
            >
              <el-input
                v-model="form.reject_reason"
                placeholder="请输入拒绝原因"
                clearable
                type="textarea"
              />
            </jf-form-item>
          </el-col>
          <el-col
            v-if="title == '查看' && form.status == '3' && form.reject_reason"
            :span="12"
          >
            <jf-form-item label="拒绝原因" :value="form.reject_reason">
            </jf-form-item>
          </el-col>
        </el-row>
      </jf-form>

      <div slot="footer" class="dialog-footer">
        <template v-if="title == '查看'">
          <el-button @click="cancel">关 闭</el-button>
        </template>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getOaTaskRecord, readOaTaskRecord } from "@/api/core/oa/oa_task_record";
import { EventBus } from "@/utils/eventBus.js";
export default {
  name: "view-dialogs",
  props: {},
  data() {
    return {
      title: "",
      open: false,
      form: {
        task: {},
      },
      rules: {
        task_id: [
          { required: true, message: "任务ID不能为空", trigger: "blur" },
        ],
        user_id: [
          { required: true, message: "接收人id不能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    showView(row, title) {
      this.reset();
      const id = row.id;
      getOaTaskRecord(id).then((response) => {
        this.form = response.data;
        this.open = true;
        this.title = title;
      });
      if (row.readed != "1") {
        readOaTaskRecord({ id: id }).then((response) => {
          EventBus.$emit("readTask", 'readOaTaskRecord');
        });
      }
    },
    reset() {
      this.form = {
        task_id: undefined,
        user_id: undefined,
        user_name: undefined,
        dept_id: undefined,
        dept_name: undefined,
        transfer_ids: undefined,
        transfer_names: undefined,
        transfer_from_ids: undefined,
        transfer_from_names: undefined,
        readed: undefined,
        read_time: undefined,
        status: undefined,
        submit_position: undefined,
        submit_position_remark: undefined,
        submit_status: undefined,
        submit_content: undefined,
        submit_files: undefined,
        submit_time: undefined,
        score: undefined,
        approve_status: undefined,
        approve_content: undefined,
        approve_by: undefined,
        approve_time: undefined,
        task: {},
      };
      this.resetForm("form");
    },
    cancel() {
      this.open = false;
      this.reset();
    },
  },
};
</script>
