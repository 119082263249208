<script>
import SvgIcon from '@/components/SvgIcon'// svg component
import Vue from 'vue'
Vue.component('svg-icon', SvgIcon)
export default {
  name: 'MenuItem',
  functional: true,
  props: {
    icon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    collapse: {
      type: Boolean,
      default: false
    }
  },

  render(h, context) {
    const { icon, title, collapse } = context.props
    const vnodes = []
    if (icon) {
      if (icon.indexOf('el-icon-') === 0) {
        vnodes.push(<i class={icon + ' el-icon'}></i>)
      } else if (icon.indexOf('fa fa-') === 0) {
        vnodes.push(<i class={icon}></i>)
      } else {
        vnodes.push(<div class={'svg-icon-w'}><svg-icon icon-class={icon} /></div>)
      }
    }

    if (title) {
      vnodes.push(<span slot='title'>{(title)}</span>)
    }
    return vnodes
  }
}
</script>

<style lang='css' scoped>
.fa {
  /* width: 24px; */
  padding: 0 5px;
  color: currentColor;
  margin-right: 5px;
  text-align: center;
}

.d-inline {
  display: inline;
}

.svg-icon-w {
  vertical-align: middle;
  width: 24px;
  text-align: center;
  display: inline;
  padding: 0 5px;
  margin-right: 5px;
}

/*#app .hideSidebar .el-icon{
    margin-left: 26px;
}
#app .hideSidebar .fa{
    margin-left: 20px;
}
.el-icon {
  margin-right: 18px;
  width:0.85em;
  height:0.85em;
  vertical-align:-0.15em;
  margin-left:-0.1em;
  color:currentColor;
  overflow:hidden;
}
.fa {
  font-size: 1.1em;
  color: currentColor;
  margin-right: 16px;
}*/
</style>
