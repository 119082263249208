export const themeModes = [
    {
        label: '清新',
        value: 'light',
        themes: [
            {
                label: '蓝白',
                value: '#1890ff',
            },
            {
                label: '绿白',
                value: '#41b584',
            },
            {
                label: '紫白',
                value: '#6954f0'
            }
        ]
    },
    {
        label: '深色',
        value: 'dark',
        themes: [
            {
                label: '蓝黑',
                value: '#1890ff',
            },
            {
                label: '绿黑',
                value: '#41b584',
            },
            {
                label: '紫黑',
                value: '#6954f0'
            }
        ]
    }
  ]