<template>
    <div>
        <!-- 综合顶部菜单栏 -->
        <el-tabs
            class="comprehensive-tabs"
            tab-position="top"
            :stretch="true"
            v-model="currentTab"
            @tab-click="handleTabClick"
        >
            <template v-for="(route, index) in tabList">
                <el-tab-pane
                    v-if="!route.hidden"
                    :key="index"
                    :name="`${index}`"
                    style="position: relative"
                >
                    <column-grid slot="label" :item="route" />
                </el-tab-pane>
            </template>
        </el-tabs>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isExternal } from "@/utils/validate";
import path from "path";
export default {
    name: "ComprehensiveNav",
    data() {
        return {
            currentTab: "0",
            mainRouteId: "",
        };
    },
    computed: {
        ...mapGetters({
            device: "settings/device",
            routes: "routes/routes",
            mainRoutes: "routes/mainRoutes",
            layout: "settings/layout",
            currentRoute: "routes/currentRoute",
        }),
        tabList() {
            let arr = [];
            arr =
                (this.mainRoutes &&
                    this.mainRoutes.filter((item) => !item.hidden)) ||
                [];
            return arr;
        },
        innerWidth() {
            return document.body.getBoundingClientRect().width;
        },
    },
    watch: {
        $route() {
            this.currentTab = this.getActiveTab();
        },
        innerWidth: {
            handler(val) {
                console.log(val);
            },
            deep: true,
            immediate: true,
        },
    },
    mounted() {
        this.currentTab = this.getActiveTab();
        if (this.handleIsMobile() > 1500) {
            document.querySelector(".comprehensive-tabs").style.width = "800px";
        } else if (this.handleIsMobile() > 1250) {
            document.querySelector(".comprehensive-tabs").style.width = "600px";
        } else if (this.handleIsMobile() > 1000) {
            document.querySelector(".comprehensive-tabs").style.width = "400px";
        } else if (this.handleIsMobile() > 800) {
            document.querySelector(".comprehensive-tabs").style.width = "300px";
        }
    },
    methods: {
        handleIsMobile() {
            // 992
            return document.body.getBoundingClientRect().width;
        },
        getActiveTab() {
            let route = this.$route;
            let { tabList } = this;
            for (let r in tabList) {
                let proutes = tabList[r];

                if (proutes.children) {
                    let flag = 0;

                    const findChildrenDeep = (croutes, resolvePath) => {
                        if (route.path == resolvePath) {
                            flag = 1;
                            return 1;
                        }
                        if (!croutes || croutes.length <= 0) {
                            return "-1";
                        }
                        for (let c of croutes) {
                            findChildrenDeep(
                                c.children,
                                path.resolve(resolvePath, c.path)
                            );
                        }
                    };
                    findChildrenDeep(proutes.children, proutes.path);
                    if (flag == 1) {
                        return `${r}`;
                    } else if (proutes.path == "/app") {
                        return `${r}`;
                    }
                } else {
                    if (route.path == path.resolve(proute.path, "")) {
                        return `${r}`;
                    } else if (proutes.path == "/app") {
                        return `${r}`;
                    }
                }
            }
            return "0";
        },
        handleTabClick(tab) {
            const route = this.tabList[this.currentTab];
            const routePath = route.path;
            //const childrenRoute = route.children && route.children[0] && route.children[0].path || "";
            //深度获取第一层子路由的路径
            const getChildPathDeep = (childrenRoutes, resolvePath) => {
                if (childrenRoutes && childrenRoutes.length > 0) {
                    return getChildPathDeep(
                        childrenRoutes[0].children,
                        path.resolve(resolvePath, childrenRoutes[0].path)
                    );
                } else {
                    return resolvePath;
                }
            };
            const childrenRoute = getChildPathDeep(route.children, routePath);

            const target = route.meta.target;
            if (target == "_blank") {
                if (isExternal(routePath)) {
                    window.open(routePath);
                } else if (isExternal(this.fullPath)) {
                    window.open(this.fullPath);
                } else if (
                    this.$route.path !== path.resolve(routePath, childrenRoute)
                ) {
                    let routeData = this.$router.resolve(
                        path.resolve(routePath, childrenRoute)
                    );
                    window.open(routeData.href);
                }
            } else {
                if (isExternal(routePath)) {
                    window.location.href = routePath;
                } else if (isExternal(this.fullPath)) {
                    window.location.href = this.fullPath;
                } else if (
                    this.$route.path !== path.resolve(routePath, childrenRoute)
                ) {
                    this.$router.push(path.resolve(routePath, childrenRoute));
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.comprehensive-tabs {
    margin-left: 20px;
    width: 600px;
    ::v-deep {
        .el-tabs__header {
            margin: 0 0 5px;
        }

        .el-tabs__nav-wrap::after {
            background-color: #fff !important;
        }

        .el-tabs__item i {
            margin-right: 3px;
        }
        // .el-tabs__nav-scroll {
        //     width: 500px;
        // }
    }
}
@media screen and (max-width: 1200px) {
    .comprehensive-tabs {
        width: 800px;
    }
}

@media screen and (max-width: 992px) {
    .comprehensive-tabs {
        width: 700px;
    }
}

@media screen and (max-width: 768px) {
    .comprehensive-tabs {
        width: 600px;
    }
}

@media screen and (max-width: 576px) {
}
</style>