<!-- 综合侧边栏 -->
<template>
    <el-scrollbar
        class="comprehensive-bar-container"
        :class="{ 'is-collapse': collapse }"
    >
        <logo />
        <el-menu
            :default-active="activeMenu"
            :collapse="collapse"
            :collapse-transition="false"
            :default-openeds="defaultOopeneds"
            :unique-opened="uniqueOpened"
            mode="vertical"
        >
            <template v-for="route in childrenRoutes">
                <side-bar-item
                    :key="route.path"
                    :full-path="handlePath(route.path)"
                    :item="route"
                />
            </template>
        </el-menu>
    </el-scrollbar>
</template>
<script>
import { mapGetters } from "vuex";
import { defaultOopeneds, uniqueOpened } from "@/config";
import { isExternal } from "@/utils/validate";
import path from "path";
export default {
    name: "ComprehensiveBar",
    data() {
        return {
            uniqueOpened,
            currentTab: "0",
            defaultOopeneds,
            mainRouteId: "",
            currentRoute: undefined,
            rootPath: "/",
            routeTopId: "",
            childrenRoutes: [],
        };
    },
    computed: {
        ...mapGetters({
            collapse: "settings/collapse",
            routes: "routes/routes",
            mainRoutes: "routes/mainRoutes",
        }),
        activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            console.log("获取activeMenu----", meta.activeMenu, path);
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            return path;
        },
        tabList() {
            let arr = [];
            arr =
                (this.mainRoutes &&
                    this.mainRoutes.filter((item) => !item.hidden)) ||
                [];
            return arr;
        },
        // childrenRoutes() {
        //   console.log(this.rootRoute&&this.rootRoute.children,'应该全部为true')
        //   if (this.rootRoute && this.rootRoute.children ) {
        //     console.log('应该进入这里')
        //     return this.rootRoute.children
        //   } else {
        //     let currentRouter = this.tabList[this.currentTab]
        //     let children = currentRouter && currentRouter.children ? currentRouter.children : []
        //     this.rootRoute = this.tabList[this.currentTab]
        //     console.log(this.currentTab,'不应该来这里')
        //     return children
        //   }
        // },
    },
    watch: {
        $route() {
            this.currentRoute = undefined;
            this.rootRoute = undefined;
            this.rootPath = "/";
            this.routeTopId = 0;
            const { path } = this.$route;
            if (path) {
                this.initRoute(path);
            }
        },
        mainRoutes() {
            if (!this.rootRoute) {
                this.getRootRoute();
            }
        },
    },
    created() {
        const { path } = this.$route;

        if (path) {
            this.initRoute(path);
            setTimeout(() => {
                if (!this.rootRoute) {
                    this.getRootRoute();
                }
            }, 0);
        }
    },
    methods: {
        getRootRoute() {
            this.currentTab = this.getMainActiveTab();
            let currentRouter = this.tabList[this.currentTab];
            let children =
                currentRouter && currentRouter.children
                    ? currentRouter.children
                    : [];
            this.childrenRoutes = children;
            this.rootRoute = this.tabList[this.currentTab];
        },
        getMainActiveTab() {
            let route = this.$route;
            let { tabList } = this;
            for (let r in tabList) {
                let proutes = tabList[r];
                if (proutes.children) {
                    let flag = 0;
                    const findChildrenDeep = (croutes, resolvePath) => {
                        if (route.path == resolvePath) {
                            flag = 1;
                            return 1;
                        }
                        if (!croutes || croutes.length <= 0) {
                            return "-1";
                        }
                        for (let c of croutes) {
                            findChildrenDeep(
                                c.children,
                                path.resolve(resolvePath, c.path)
                            );
                        }
                    };
                    findChildrenDeep(proutes.children, proutes.path);
                    if (flag == 1) {
                        return `${r}`;
                    }
                } else {
                    if (route.path == path.resolve(proutes.path, "")) {
                        return `${r}`;
                    }
                }
            }
            return "0";
        },
        initRoute(path) {
            for (let key in this.routes) {
                let route = this.routes[key];
                if (route.children) {
                    this.findRoutePath(route, path, route.children, route.path);
                    if (this.currentRoute) {
                        break;
                    }
                }
            }
        },
        findRoutePath(rootRoute, route_path, routes, basePath = "/") {
            for (let key in routes) {
                let route = routes[key];
                if (route.menu_type == "1") {
                    const tagPath = path.resolve(basePath, route.path);
                    if (tagPath == route_path) {
                        this.$store.dispatch("routes/setCurrentRoute", {
                            current: this.currentRoute,
                            root: this.rootRoute,
                        });
                        this.currentRoute = route;
                        this.rootRoute = rootRoute;
                        this.childrenRoutes = rootRoute.children;
                        this.routeTopId = rootRoute.id;
                        this.currentTab = this.getActiveTab();
                        console.log(this.rootRoute, "rootRoute");
                        return true;
                    }
                }
                if (route.children) {
                    this.findRoutePath(
                        rootRoute,
                        route_path,
                        route.children,
                        route.path
                    );
                }
            }
        },
        getActiveTab() {
            let { tabList } = this;
            let is_exist = false;
            let is_app = -1;
            for (let r in tabList) {
                let proutes = tabList[r];
                if (proutes.id == this.routeTopId) {
                    is_exist = true;
                    return `${r}`;
                }
                if (proutes.path == "/app") {
                    is_app = `${r}`;
                }
            }
            if (!is_exist) {
                return is_app;
            }
        },
        handlePath(routePath) {
            if (isExternal(routePath)) {
                return routePath;
            }
            if (isExternal(this.fullPath)) {
                return this.fullPath;
            }
            // let parentRoute = this.tabList[this.currentTab].path;
            let parentRoute = this.rootRoute.path;
            return path.resolve(parentRoute, routePath);
        },
    },
};
</script>
<style lang="scss" scoped>
@mixin active {
    &:hover {
        background-color: var(--menu-background-active);
        color: var(--menu-color-active);

        i {
            color: var(--menu-color-active);
        }
    }

    &.is-active {
        background-color: var(--menu-background-active);
        color: var(--menu-color-active);

        i {
            color: var(--menu-color-active);
        }
    }
}

.comprehensive-bar-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: $base-z-index;
    width: $base-left-menu-width;
    height: 100vh;
    overflow: hidden;
    background: var(--bg-color);
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
    transition: width $base-transition-time;

    &.is-collapse {
        width: $base-left-menu-width-min;
        border-right: 0;

        ::v-deep {
            .el-menu {
                transition: width $base-transition-time;
            }

            .el-menu--collapse {
                border-right: 0;

                .el-submenu__icon-arrow {
                    right: 10px;
                    margin-top: -3px;
                }
            }
        }
    }

    ::v-deep {
        .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .el-menu {
            border: 0;
            background: rgba(255, 255, 255, 0);

            &.el-menu--popup {
                background: var(--menu-background);
            }

            .iconfont {
                padding-right: 3px;
                font-size: $base-font-size-default;
                color: var(--menu-color);
                font-weight: 500;
                -webkit-font-smoothing: antialiased;
            }

            i {
                color: var(--menu-color);
            }
        }

        .el-menu-item,
        .el-submenu__title {
            height: 50px;
            overflow: hidden;
            line-height: 50px;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
            color: var(--menu-color);
        }

        .el-menu-item,
        .el-submenu__title {
            @include active;
        }
    }
}
</style>
  