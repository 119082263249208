import Vue from "vue";
import App from "./App.vue";
import Element from "element-ui";
import "./assets/icons"; // icon
import "./assets/styles/element-variables.scss";
import "font-awesome/css/font-awesome.min.css"; //font-awesome css
import "normalize.css/normalize.css"; // a modern alternative to CSS resets
import "./assets/styles/vxe-table.scss";
import "@/assets/styles/index.scss"; // global css
import "@/assets/styles/ruoyi.scss"; // ruoyi css
import router from "./router";
import store from "./store";
import "./common";
import Cookies from "js-cookie";
import { getDicts } from "@/api/core/data/dict/data";
import { getConfigKey } from "@/api/core/data/config/config";
import VXETable from "vxe-table";
import XEUtils from "xe-utils";
import permission from "./directive/permission";
import "./directive";


// 编辑器
import VueUeditorWrap from "vue-ueditor-wrap"; // ES6 Module
import VFormRender from '@/vform/components/form-render/index'
import TableMultiLevelColumn from '@/vform/components/form-designer/form-widget/table-multi-level-column'
import TableHighLevelColumn from '@/vform/components/form-render/table-high-level-column'

import {
  formatDuration,
  parseTime,
  resetForm,
  addDateRange,
  selectDictLabel,
  download,
  handleTree,
  defaultQlogic,
  downloadbyurl,
  DatetounixTimestamp,
  unixTimestamptoDate,
  deepCopy,
} from "@/utils/ruoyi";
import Components from "@/components";
import CoreComponents from "@/views/core/components";
import StuComponents from "@/views/stu/components";
import GbpxComponents from "@/views/gbpx/components";
import VueClipboard from "vue-clipboard2";
import Moment from "moment";
import "moment/locale/zh-cn";
import pubsub from "pubsub-js";

// 全局方法挂载
Vue.prototype.Pubsub = pubsub;
Vue.prototype.XEUtils = XEUtils;
Vue.prototype.getDicts = getDicts;
Vue.prototype.getConfigKey = getConfigKey;
Vue.prototype.parseTime = parseTime;
Vue.prototype.formatDuration = formatDuration;
Vue.prototype.resetForm = resetForm;
Vue.prototype.addDateRange = addDateRange;
Vue.prototype.deepCopy = deepCopy;
Vue.prototype.selectDictLabel = selectDictLabel;
Vue.prototype.download = download;
Vue.prototype.downloadbyurl = downloadbyurl;
Vue.prototype.handleTree = handleTree;
Vue.prototype.defaultQlogic = defaultQlogic;
Vue.prototype.DatetounixTimestamp = DatetounixTimestamp;
Vue.prototype.unixTimestamptoDate = unixTimestamptoDate;
Vue.prototype.apiUrl = process.env.VUE_APP_BASE_API;
Vue.prototype.moment = Moment;

Vue.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "success",
  });
};

Vue.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "error",
  });
};

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
};

Vue.prototype.hasPermi = function (value) {
  const all_permission = "*/*/*";
  const permissions = store.getters && store.getters["user/permissions"];

  const permissionFlag = value;

  const hasPermissions = permissions.some((permission) => {
    return all_permission === permission || permissionFlag.includes(permission);
  });

  return hasPermissions;
};

// 全局组件挂载
Vue.component("vue-ueditor-wrap", VueUeditorWrap);
Vue.component('VFormRender', VFormRender)
Vue.component(TableMultiLevelColumn.name, TableMultiLevelColumn)
Vue.component(TableHighLevelColumn.name, TableHighLevelColumn)
Vue.use(Components);
Vue.use(CoreComponents);
Vue.use(StuComponents);
Vue.use(GbpxComponents);

Vue.use(VueClipboard);
Vue.use(permission);

Vue.use(Element, {
  size: Cookies.get("size") || "medium", // set element-ui default size
});

//  VXETable 全局配置
Vue.use(VXETable, {
  size: Cookies.get("size") || "medium", // set element-ui default size
  pager: {
    size: null,
    autoHidden: false,
    perfect: true,
    pageSize: 10,
    pagerCount: 7,
    pageSizes: [10, 15, 20, 50, 100],
    layouts: [
      "PrevJump",
      "PrevPage",
      "Number",
      "NextPage",
      "NextJump",
      "Sizes",
      "FullJump",
      "Total",
    ],
  },
  grid: {
    toolbarConfig: {
      perfect: true,
      zoom: true,
      custom: true,
    },
  },
  icon: {
    TABLE_TREE_OPEN: "el-icon-arrow-right el-table__expand-icon--expanded",
    TABLE_TREE_CLOSE: "el-icon-arrow-right",
  },
});

// 自定义全局的格式化处理函数
VXETable.formats.mixin({
  // 格式化日期，默认 yyyy-MM-dd HH:mm:ss
  formatDate({ cellValue }, format) {
    if (
      cellValue == null ||
      cellValue == 0 ||
      cellValue == "0" ||
      cellValue == ""
    ) {
      return "(无)";
    }
    var regPos = /^\d{9,10}$/; //时间戳
    //处理Unix时间戳
    if (regPos.test(cellValue)) {
      var ttime = cellValue * 1000;
      cellValue = new Date(ttime);
    }
    return XEUtils.toDateString(cellValue, format || "yyyy-MM-dd HH:mm:ss");
  },
  //时间戳转日期
  unixTimestampToDate(value) {
    var ttime = value * 1000;
    return new Date(ttime);
  },

  // 四舍五入金额，每隔3位逗号分隔，默认2位数
  formatAmount({ cellValue }, digits = 2) {
    return XEUtils.commafy(XEUtils.toNumber(cellValue), {
      digits,
    });
  },
  // 格式化银行卡，默认每4位空格隔开
  formatBankcard({ cellValue }) {
    return XEUtils.commafy(XEUtils.toValueString(cellValue), {
      spaceNumber: 4,
      separator: " ",
    });
  },
  // 四舍五入,默认两位数
  formatFixedNumber({ cellValue }, digits = 2) {
    return XEUtils.toFixed(XEUtils.round(cellValue, digits), digits);
  },
  // 向下舍入,默认两位数
  formatCutNumber({ cellValue }, digits = 2) {
    return XEUtils.toFixed(XEUtils.floor(cellValue, digits), digits);
  },
  //格式化时长，单位秒
  formatDuration({ cellValue }) {
    return Vue.prototype.formatDuration(cellValue);
  },
});
/**
 * @description 生产环境默认都使用mock，如果正式用于生产环境时，记得去掉
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('@/utils/staticMock')
//   mockXHR()
// }

import { getIndexStyle } from "@/utils/colorTool.js";

/* 初始化主题样式 */
getIndexStyle().then(() => {
  store.dispatch("settings/setThemeColor");
});

Vue.config.productionTip = false;
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
