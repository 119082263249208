/** 公共文件引入 */
import Vue from 'vue'
import '@/styles/index.scss'
import './element'
import './support'
import NoticeTool from '@/utils/noticeTool.js'
import '@/config/routeGuard.js'
// 阿里图标库
import "@/assets/iconfont/iconfont.css";

//公共组件导入
import '@/layouts/export'

Vue.use(NoticeTool)