<!-- 横向顶部栏 -->
<template>
    <div class="top-bar-container">
        <div class="qz-micro-main">
            <el-row>
                <el-col :xl="6" :lg="6" :md="6" :sm="6" :xs="6">
                    <logo />
                </el-col>
                <el-col :xl="12" :lg="12" :md="12" :sm="12" :xs="12">
                    <el-menu
                        :default-active="activeMenu"
                        mode="horizontal"
                        menu-trigger="hover"
                    >
                        <template v-for="route in main_routes">
                            <side-bar-item
                                v-if="!route.hidden"
                                :key="route.path"
                                :full-path="route.path"
                                :item="route"
                            />
                        </template>
                    </el-menu>
                </el-col>
                <el-col :xl="6" :lg="6" :md="6" :sm="6" :xs="6">
                    <div class="right-panel">
                        <back-to-main-app />
                        <icon-badge
                            iconName="el-icon-message-solid"
                            :value="120"
                            :width="300"
                        >
                            <template>
                                <el-table :data="gridData">
                                    <el-table-column
                                        width="150"
                                        property="date"
                                        label="日期"
                                    ></el-table-column>
                                    <el-table-column
                                        width="100"
                                        property="name"
                                        label="姓名"
                                    ></el-table-column>
                                    <el-table-column
                                        width="300"
                                        property="address"
                                        label="地址"
                                    ></el-table-column>
                                </el-table>
                            </template>
                        </icon-badge>
                        <icon-badge iconName="el-icon-document" :value="8" />
                        <theme-bar />
                        <my-avatar />
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
  
<script>
import variables from "@/styles/variables.scss";
import { mapGetters } from "vuex";
import path from "path";
export default {
    name: "TopBar",
    data() {
        return {
            pulse: false,
            menuTrigger: "hover",
            gridData: [
                {
                    date: "2016-05-02",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                },
                {
                    date: "2016-05-04",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                },
                {
                    date: "2016-05-01",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                },
                {
                    date: "2016-05-03",
                    name: "王小虎",
                    address: "上海市普陀区金沙江路 1518 弄",
                },
            ],
            mainRouteId: "",
            currentRoute: undefined,
            rootPath: "/",
            routeTopId: "",
            newMainRoutes: [],
        };
    },
    computed: {
        ...mapGetters({
            routes: "routes/routes",
            visitedRoutes: "tabsBar/visitedRoutes",
            mainRoutes: "routes/mainRoutes",
        }),
        activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            return path;
        },
        variables() {
            return variables;
        },
        main_routes() {
            let { mainRoutes } = this;
            if (!this.routeTopId) {
                if (mainRoutes.length > 7) {
                    let list = mainRoutes.slice(0, 7);
           
                    list.push({
                        children: mainRoutes.slice(7, mainRoutes.length),
                        title: "更多",
                        meta: {
                            title: "更多",
                            icon: "dict",
                            noCache: false,
                            affix: false,
                            noKeepAlive: false,
                        },
                        path: "/index",
                    });
                    return list;
                }
                return mainRoutes.slice(0, 7);
            }
            for (let r in mainRoutes) {
                let proutes = mainRoutes[r];
                if (proutes.id == this.routeTopId) {
                    if (mainRoutes.length > 7) {
                        let list = mainRoutes.slice(0, 7);
           
                    list.push({
                        children: mainRoutes.slice(7, mainRoutes.length),
                        title: "更多",
                        meta: {
                            title: "更多",
                            icon: "list",
                            noCache: false,
                            affix: false,
                            noKeepAlive: false,
                        },
                        path: "/index",
                    });
                    return list;
                    }
                    return mainRoutes.slice(0, 7);
                }
            }
            // return mainRoutes.concat([this.rootRoute]).sort((a, b) => a.sort - b.sort)
            if (mainRoutes.concat([this.rootRoute]).length > 7) {
                let list = mainRoutes.slice(0, 7);
                    list.push({
                        children: mainRoutes.slice(7, mainRoutes.length),
                        title: "更多",
                        meta: {
                            title: "更多",
                            icon: "dict",
                            noCache: false,
                            affix: false,
                            noKeepAlive: false,
                        },
                        path: "/index",
                    });
                    return list;
            }
            return mainRoutes.concat([this.rootRoute]).slice(0, 7);
        },
    },
    watch: {
        $route() {
            this.currentRoute = undefined;
            this.rootRoute = undefined;
            this.rootPath = "/";
            this.routeTopId = 0;
            const { path } = this.$route;
            if (path) {
                this.initRoute(path);
            }
        },
    },
    mounted() {
        const { path } = this.$route;
        if (path) {
            this.initRoute(path);
        }
    },
    methods: {
        async refreshRoute() {
            this.$baseEventBus.$emit("reload-router-view");
            this.pulse = true;
            setTimeout(() => {
                this.pulse = false;
            }, 1000);
        },
        initRoute(path) {
            let { routes } = this;
            for (let key in routes) {
                let route = routes[key];
                if (route.children) {
                    this.findRoutePath(route, path, route.children, route.path);
                    if (this.currentRoute) {
                        break;
                    }
                }
            }
        },
        findRoutePath(rootRoute, route_path, routes, basePath = "/") {
            for (let key in routes) {
                let route = routes[key];
                if (route.menu_type == "1") {
                    const tagPath = path.resolve(basePath, route.path);
                    if (tagPath == route_path) {
                        this.$store.dispatch("routes/setCurrentRoute", {
                            current: this.currentRoute,
                            root: this.rootRoute,
                        });
                        this.currentRoute = route;
                        this.rootRoute = rootRoute;
                        this.routeTopId = rootRoute.id;
                        return true;
                    }
                }
                if (route.children) {
                    this.findRoutePath(
                        rootRoute,
                        route_path,
                        route.children,
                        route.path
                    );
                }
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.top-bar-container {
    display: flex;
    align-items: center;
    justify-items: flex-end;
    height: $base-top-bar-height;
    background: var(--bg-color);

    .qz-micro-main {
        width: 95% !important;
        ::v-deep {
            .el-menu {
                .iconfont {
                    padding-right: 3px;
                }

                &.el-menu--horizontal {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    height: $base-top-bar-height;
                    border-bottom: 0 solid transparent !important;
                    background: var(--bg-color) !important;

                    .el-menu-item,
                    .el-submenu__title {
                        padding: 0 15px;
                    }

                    @media only screen and (max-width: 767px) {
                        .el-menu-item,
                        .el-submenu__title {
                            padding: 0 8px;
                        }

                        li:nth-child(4),
                        li:nth-child(5) {
                            display: none !important;
                        }
                    }

                    > .el-menu-item {
                        height: $base-menu-item-height;
                        line-height: $base-menu-item-height;
                        margin-right: 4px;
                        background-color: var(--menu-background);
                        color: var(--menu-color);

                        i {
                            color: var(--menu-color);
                        }
                    }

                    > .el-submenu {
                        margin-right: 4px;

                        &.is-active,
                        &:hover {
                            > .el-submenu__title {
                                background-color: var(--menu-background-active);
                                color: var(--menu-color-active);
                                border-bottom: 2px solid
                                    var(--menu-color-active);

                                i {
                                    color: var(--menu-color-active);
                                }
                            }
                        }

                        .el-submenu__title {
                            height: $base-menu-item-height;
                            line-height: $base-menu-item-height;
                            background-color: var(--menu-background);
                            color: var(--menu-color);

                            i {
                                color: var(--menu-color);
                            }
                        }
                    }
                }

                &--horizontal {
                    .el-menu {
                        background-color: var(--menu-background);

                        .el-menu-item {
                            height: $base-menu-item-height;
                            line-height: $base-menu-item-height;
                            background-color: var(--menu-background);
                            color: var(--menu-color);

                            i {
                                color: var(--menu-color);
                            }

                            &.is-active,
                            &:hover {
                                background-color: var(--menu-background-active);
                                color: var(--menu-color-active);

                                i {
                                    color: var(--menu-color-active);
                                }
                            }
                        }

                        .el-submenu {
                            .el-submenu__title {
                                height: $base-menu-item-height;
                                line-height: $base-menu-item-height;
                                background-color: var(--menu-background);
                                color: var(--menu-color);

                                i {
                                    color: var(--menu-color);
                                }
                            }

                            &.is-active,
                            &:hover {
                                .el-submenu__title {
                                    background-color: var(
                                        --menu-background-active
                                    );
                                    color: var(--menu-color-active);

                                    i {
                                        color: var(--menu-color-active);
                                    }
                                }
                            }
                        }

                        &.el-menu--popup {
                            .el-submenu__title {
                                border-bottom: 0 solid transparent !important;
                            }
                        }
                    }

                    > .el-menu-item {
                        background-color: var(--menu-background);
                        color: var(--menu-color);

                        &.is-active,
                        &:hover {
                            background-color: var(--menu-background-active);
                            color: var(--menu-color-active);
                            border-bottom: 2px solid var(--menu-color-active);

                            i {
                                color: var(--menu-color-active);
                            }
                        }

                        .el-tag {
                            margin-top: calc(
                                #{$base-top-bar-height} / 2 - 7.5px
                            );
                            margin-left: 5px;
                        }

                        @media only screen and (max-width: 1199px) {
                            .el-tag {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .right-panel {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: $base-top-bar-height;

        ::v-deep {
            .user-name {
                color: var(--color-whitle);
            }

            .user-name + i {
                color: var(--color-whitle);
            }

            .el-badge {
                margin-right: 15px;
            }
        }
    }
}
</style>
  