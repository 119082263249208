<template>
    <div class="column-grid">
        <!-- <i v-if="item.meta && item.meta.icon" :class="['iconfont', `icon-${item.meta.icon}`]" ></i> -->
        <!-- <span>{{ item.meta.title }}</span> -->
        <icon-item
            :icon="item.meta.icon || (item.meta && item.meta.icon)"
            :title="item.meta.title"
        />
    </div>
</template>

<script>
import IconItem from "../../SideBar/components/Item";
export default {
    name: "ColumnGrid",
    components: { IconItem },
    props: {
        item: {
            type: Object,
            required: true,
        },
    },
};
</script>