import request from '@/utils/request'

export function listStuTurn(query) {
  return request({
    url: '/stu/stuturn/list',
    method: 'get',
    params: query
  })
}

export function getStuTurn(id) {
  return request({
    url: '/stu/stuturn/get?id=' + id,
    method: 'get'
  })
}

export function addStuTurn(data) {
  return request({
    url: '/stu/stuturn/add',
    method: 'post',
    data: data
  })
}

export function editStuTurn(data) {
  return request({
    url: '/stu/stuturn/edit',
    method: 'post',
    data: data
  })
}

export function delStuTurn(ids) {
  return request({
    url: '/stu/stuturn/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

