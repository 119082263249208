<template>
  <footer
    v-show="footerCopyright"
    class="qz-footer-copyright"
    :class="{ border }"
    :style="{ color: color }"
  >
    Copyright
    <i class="iconfont icon-banquan-kuai"></i>
    <!-- {{ title }} {{ fullYear }} -->
    {{ copyright }}版权所有
  </footer>
</template>

<script>
import { footerCopyright, title, copyright } from "@/config";

export default {
  name: "FooterCopyright",
  props: {
    color: {
      type: String,
      default: "rgba(0, 0, 0, 0.45)",
    },
    border: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fullYear: new Date().getFullYear(),
      title,
      footerCopyright,
      copyright,
    };
  },
};
</script>


<style lang="scss" scoped>
.qz-footer-copyright {
  height: 55px;
  line-height: 55px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  letter-spacing: 2px;
  text-align: center;
  position: absolute;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  &.border {
    border-top: 1px dashed $base-border-color;
  }
}
</style>