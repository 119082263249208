import MajorSelect from './major_select'
import StudentSelect from './student_select'
import ClassesSelect from './classes_select'
import TurnSelect from './turn_select'
import AssistantSelect from './assistant_select'

export default {
  install: (Vue) => {
    Vue.component('major-select', MajorSelect)
    Vue.component('student-select', StudentSelect)
    Vue.component('classes-select', ClassesSelect)
    Vue.component('turn-select', TurnSelect)
    Vue.component('assistant-select', AssistantSelect)
  }
}