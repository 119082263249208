<template>
    <form-item-wrapper
        :designer="designer"
        :field="field"
        :rules="rules"
        :design-state="designState"
        :parent-widget="parentWidget"
        :parent-list="parentList"
        :index-of-parent-list="indexOfParentList"
        :sub-form-row-index="subFormRowIndex"
        :sub-form-col-index="subFormColIndex"
        :sub-form-row-id="subFormRowId"
    >
        <div class="d-flex">
            <el-input
                style="width: 300px"
                ref="fieldEditor"
                v-model="fieldModel"
                v-show="!isReadMode"
                :disabled="field.options.disabled"
                :readonly="field.options.readonly"
                class="hide-spin-button"
                :type="inputType"
                :show-password="field.options.showPassword"
                :placeholder="field.options.placeholder"
                :clearable="field.options.clearable"
                :minlength="field.options.minLength"
                :maxlength="field.options.maxLength"
                :show-word-limit="field.options.showWordLimit"
                :prefix-icon="field.options.prefixIcon"
                :suffix-icon="field.options.suffixIcon"
                @focus="handleFocusCustomEvent"
                @blur="handleBlurCustomEvent"
                @input="handleInputCustomEvent"
                @change="handleChangeEvent"
            >
            </el-input>
            <el-button
                v-if="field.options.appendButton"
                :disabled="
                    field.options.disabled || field.options.appendButtonDisabled
                "
                :class="field.options.buttonIcon"
                @click.native="open"
                type="primary"
                class="open-btn"
            ></el-button>
        </div>
        <el-dialog
            :title="field.options.title"
            :visible.sync="dialogVisible"
            width="30%"
            append-to-body
            :before-close="handleClose"
        >
            <div>
                <jf-table
                    :loading="loading"
                    :query-params="queryParams"
                    :columns="tableColumn"
                    :data="dataList"
                    @checkbox-change="checkboxChangeEvent"
                    @checkbox-all="checkboxChangeEvent"
                    @page-change="handleSizeChange"
                    :operate="false"
                    :total="total"
                >
                    <template slot="roles" slot-scope="{ row }">
                        <span v-for="(item, index) of row.roles" :key="index">
                            {{ item.name }}
                        </span>
                    </template>
                </jf-table>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button
                    v-if="!field.options.cancelButtonHidden"
                    @click="handleClose"
                >
                    {{
                        field.options.cancelButtonLabel ||
                        this.i18nt("designer.hint.cancel")
                    }}</el-button
                >
                <el-button
                    v-if="!field.options.okButtonHidden"
                    type="primary"
                    @click="handleOkClick"
                >
                    {{
                        field.options.okButtonLabel ||
                        this.i18nt("designer.hint.confirm")
                    }}</el-button
                >
            </div>
        </el-dialog>
    </form-item-wrapper>
</template>

<script>
import FormItemWrapper from "./form-item-wrapper";
import emitter from "@/vform/utils/emitter";
import i18n, { translate } from "@/vform/utils/i18n";
import fieldMixin from "@/vform/components/form-designer/form-widget/field-widget/fieldMixin";
import request from "@/utils/request";
import { ReFormatQueryStr } from "@/utils/formatquerystr";
export default {
    name: "list-selection-widget",
    componentName: "FieldWidget", //必须固定为FieldWidget，用于接收父级组件的broadcast事件
    mixins: [emitter, fieldMixin, i18n],
    props: {
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designer: Object,

        designState: {
            type: Boolean,
            default: false,
        },

        subFormRowIndex: {
            /* 子表单组件行索引，从0开始计数 */ type: Number,
            default: -1,
        },
        subFormColIndex: {
            /* 子表单组件列索引，从0开始计数 */ type: Number,
            default: -1,
        },
        subFormRowId: {
            /* 子表单组件行Id，唯一id且不可变 */ type: String,
            default: "",
        },
    },
    components: {
        FormItemWrapper,
    },

    inject: ["refList", "globalOptionData", "globalModel"],

    data() {
        var validateLength = (rule, value, callback) => {
            if (value.length < this.field.options.minLength) {
                callback(
                    new Error(`输入不得小于${this.field.options.minLength}`)
                );
            } else {
                callback();
            }
        };
        return {
            oldFieldValue: null, //field组件change之前的值
            fieldModel: null,
            rules: [],
            dialogVisible: false,
            // 数据列
            tableColumn: [
                {
                    type: "checkbox",
                    width: 60,
                    fixed: "left",
                },
                {
                    field: "id",
                    title: "ID",
                    width: 100,
                    fixed: "left",
                },

                // {
                // 	field: "",
                // 	title: "操作",
                // 	resizable: false,
                // 	width: 180,
                // 	fixed: "right",
                // 	align: "center",
                // 	slots: {
                // 		default: "defaultopr"
                // 	},
                // },
            ],
            // 表格数据
            dataList: [],
            total: 0,
            queryParams: {
                page: 1,
                psize: 10,
                // fieldval: {},
                // querytype: {
                //     module_name: "like",
                // },
                // sortinfo: { id: "desc" },
            },
            loading: false,
        };
    },
    computed: {
        inputType() {
            if (this.field.options.type === "number") {
                return "text"; //当input的type设置为number时，如果输入非数字字符，则v-model拿到的值为空字符串，无法实现输入校验！故屏蔽之！！
            }

            return this.field.options.type;
        },
    },
    beforeCreate() {
        /* 这里不能访问方法和属性！！ */
    },

    created() {
        /* 注意：子组件mounted在父组件created之后、父组件mounted之前触发，故子组件mounted需要用到的prop
         需要在父组件created中初始化！！ */
        this.registerToRefList();
        this.initFieldModel();
        this.initEventHandler();
        this.buildFieldRules();

        this.handleOnCreated();
    },

    mounted() {
        this.handleOnMounted();
    },

    beforeDestroy() {
        this.unregisterFromRefList();
    },

    methods: {
        open() {
            this.dialogVisible = true;
            this.getList();
        },
        getList() {
            let apiData = {
                url: this.field.options.api,
                method: this.field.options.apiMethod,
            };
            // let obj = {};
            // let arr = this.field.options.requestParams
            //     ? this.field.options.requestParams.split(";")
            //     : [];
            // for (let i = 0; i < arr.length; i++) {
            //     let arr1 = arr[i].split(":");
            //     obj[arr1[0]] = arr1[1];
            // }

            // if (this.field.options.apiMethod === "get") {
            //     apiData["params"] = Object.assign(obj, this.queryParams);
            // } else {
            //     var data = {
            //         mid: 89,
            //         page: this.queryParams.page,
            //         psize:  this.queryParams.psize,
            //         qstr: {},
            //     };

            //     var tjson = ReFormatQueryStr(this.queryParams);

            //     data.qstr = JSON.stringify(tjson);
            //     apiData["data"] = data;
            // }
            request(apiData).then((res) => {});
        },
        handleClose() {
            this.dialogVisible = false;
        },
        handleOkClick() {},
        checkboxChangeEvent(e) {},
        handleSizeChange() {},
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/global.scss"; //* form-item-wrapper已引入，还需要重复引入吗？ *//
.open-btn {
    margin-left: -3px;
    position: relative;
    z-index: 1;
}
</style>
