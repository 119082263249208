//重新格式化列表查询参数
//返回:json对象
//
//列子 {"age":{"=":20},"city":{"in":["桂林","南宁"]}}
export function ReFormatQueryStr(queryparam) {
    var fieldquerytype = queryparam.querytype
    var qfieldval = queryparam.fieldval
    var sortinfo = queryparam.sortinfo

    var qjson = {}
    for (let qfield in qfieldval) {
        let op = fieldquerytype&&fieldquerytype[qfield] ? fieldquerytype[qfield] : "=";//得到操作符

        qjson[qfield] = qjson[qfield] || {};

        if(qfieldval[qfield]&&qfieldval[qfield]!=""){
            qjson[qfield][op] = qfieldval[qfield]
        }else if(typeof qfieldval[qfield]==='number' &&qfieldval[qfield]*1===0){
            qjson[qfield][op] = qfieldval[qfield]
        }
        


    }

    //排序
    if (sortinfo && sortinfo != null) {
        for (let sf in sortinfo) {
            qjson[sf] = qjson[sf] || {}
            qjson[sf]["orderby"] = sortinfo[sf]
        }

    }

    return qjson

}