import request from '@/utils/request'

export function listGbpxCourseCategory(query) {
  return request({
    url: '/gbpx/gbpxcoursecategory/list/a',
    method: 'get',
    params: query
  })
}

export function listGbpxCourseCategoryU(query) {
  return request({
    url: '/gbpx/gbpxcoursecategory/list/u',
    method: 'get',
    params: query
  })
}

export function getGbpxCourseCategory(id) {
  return request({
    url: '/gbpx/gbpxcoursecategory/get?id=' + id,
    method: 'get'
  })
}

export function addGbpxCourseCategory(data) {
  return request({
    url: '/gbpx/gbpxcoursecategory/add',
    method: 'post',
    data: data
  })
}

export function editGbpxCourseCategory(data) {
  return request({
    url: '/gbpx/gbpxcoursecategory/edit',
    method: 'post',
    data: data
  })
}

export function delGbpxCourseCategory(ids) {
  return request({
    url: '/gbpx/gbpxcoursecategory/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

