<template>
  <grid-select 
    v-model="selectedValue" 
    :label="label" 
    :defaultLabel="defaultLabel"
    :valueField="valueField"
    :labelField="labelField"
    :placeholder="placeholder"
    :size="size" 
    :multiple="multiple"
    :searchItems="major_no ? searchItemsWithoutMajor : (dept_no ? searchItemsWithoutDept: searchItems)"
    :gridItems="gridItems"
    :getDataList="listStuClassesFormatter"
    @change="handleChange"
  ></grid-select>
</template>

<script>
import { listStuClasses } from "@/api/stu/stu_classes";

export default {
  name: 'classes-select',
  props: {
    value: {
      type: [String, Number],
      default: () => { return null }
    },
    label: {
      type: String,
      default: () => { return null }
    },
    defaultLabel: {
      type: String,
      default: () => { return null }
    },
    valueField: {
      type: String,
      default: () => { return "id" }
    },
    labelField: {
      type: String,
      default: () => { return "name" }
    },
    placeholder: {
      type: String,
      default: () => { return '请选择' }
    },
    size: {
      type: String,
      default: () => { return 'medium' }
    },
    dept_no: {
      type: [String, Number],
      default: () => { return undefined }
    },
    major_no: {
      type: [String, Number],
      default: () => { return undefined }
    },
    multiple: {
      type: Boolean,
      default: () => { return false }
    }
  },
  data() {
    return {
      searchItems: [{field: 'no', title: '班级编号'}, {field: 'name', title: '班级名称'}, {field: 'dept_no', title: '院系', ctype: 'dept', valueField: 'no'}, {field: 'major_no', title: '专业', ctype: 'major', valueField: 'no'}],
      searchItemsWithoutDept: [{field: 'no', title: '班级编号'}, {field: 'name', title: '班级名称'}, {field: 'major_no', title: '专业', ctype: 'major', valueField: 'no'}],
      searchItemsWithoutMajor: [{field: 'no', title: '班级编号'}, {field: 'name', title: '班级名称'}],
      gridItems: [{field: 'no', title: '班级编号'}, {field: 'name', title: '班级名称'}, {field: 'dept_name', title: '院系'}, {field: 'major_name', title: '专业'}],
      selectedValue: this.value
    }
  },
  created() {
  },
  methods: {
    handleChange(e1, e2) {
      this.$emit('input', e1)
      this.$emit('change', e1, e2)
    },
    listStuClassesFormatter(param) {
      if (this.dept_no && this.major_no) {
        return listStuClasses({
          ...param,
          dept_no: this.dept_no,
          major_no: this.major_no
        })
      } else if (this.dept_no) {
        return listStuClasses({
          ...param,
          dept_no: this.dept_no
        })
      } else if (this.major_no) {
        return listStuClasses({
          ...param,
          major_no: this.major_no
        })
      }
      return listStuClasses(param)
    }
  },
  watch: {
    value(val) {
      this.selectedValue = val
    }
  }
}
</script>
