<template>
    <form-item-wrapper
        :designer="designer"
        :field="field"
        :rules="rules"
        :design-state="designState"
        :parent-widget="parentWidget"
        :parent-list="parentList"
        :index-of-parent-list="indexOfParentList"
        :sub-form-row-index="subFormRowIndex"
        :sub-form-col-index="subFormColIndex"
        :sub-form-row-id="subFormRowId"
    >
        <el-upload
            v-if="diyUploadShow"
            ref="fieldEditor"
            :disabled="field.options.disabled || isReadMode"
            :action="realUploadURL"
            :headers="uploadHeaders"
            :data="uploadData"
            :accept="accept"
            list-type="picture-card"
            :with-credentials="field.options.withCredentials"
            :multiple="field.options.multipleSelect"
            :file-list="fileList"
            :show-file-list="field.options.showFileList"
            :class="{ hideUploadDiv: uploadBtnHidden || isReadMode }"
            :limit="field.options.limit"
            :on-exceed="handlePictureExceed"
            :on-preview="handlePicturePreview"
            :before-upload="beforePictureUpload"
            :on-success="handlePictureUpload"
            :on-error="handleUploadError"
            :http-request="uploadDss"
            :on-remove="handlePictureRemove"
        >
            <div
                slot="tip"
                class="el-upload__tip"
                v-if="!!field.options.uploadTip"
            >
                {{ field.options.uploadTip }}
            </div>
            <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <!-- el-upload增加:name="field.options.name"后，会导致又拍云上传失败！故删除之！！ -->
        <el-upload
            v-else
            ref="fieldEditor"
            :disabled="field.options.disabled || isReadMode"
            :action="realUploadURL"
            :headers="uploadHeaders"
            :data="uploadData"
            :accept="accept"
            list-type="picture-card"
            :with-credentials="field.options.withCredentials"
            :multiple="field.options.multipleSelect"
            :file-list="fileList"
            :show-file-list="field.options.showFileList"
            :class="{ hideUploadDiv: uploadBtnHidden || isReadMode }"
            :limit="field.options.limit"
            :on-exceed="handlePictureExceed"
            :on-preview="handlePicturePreview"
            :before-upload="beforePictureUpload"
            :on-success="handlePictureUpload"
            :on-error="handleUploadError"
            :on-remove="handlePictureRemove"
        >
            <!-- el-upload增加:name="field.options.name"后，会导致又拍云上传失败！故删除之！！ -->

            <div
                slot="tip"
                class="el-upload__tip"
                v-if="!!field.options.uploadTip"
            >
                {{ field.options.uploadTip }}
            </div>
            <i class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>

        <div class="" style="font-size: 12px; color: red">
            可上传格式：{{ getFileTypes(field.options.fileTypes) }}
        </div>
        <el-dialog
            title=""
            v-if="showPreviewDialogFlag"
            :visible.sync="showPreviewDialogFlag"
            v-dialog-drag
            append-to-body
            width="60%"
            :show-close="true"
            custom-class="drag-dialog small-padding-dialog"
            :close-on-click-modal="true"
            :close-on-press-escape="true"
            :destroy-on-close="true"
        >
            <img :src="previewUrl" width="100%" alt="" />
        </el-dialog>
    </form-item-wrapper>
</template>

<script>
import FormItemWrapper from "./form-item-wrapper";
import emitter from "@/vform/utils/emitter";
import i18n, { translate } from "@/vform/utils/i18n";
import { deepClone, evalFn } from "@/vform/utils/util";
import { getAccessToken } from "@/utils/tokenTool";
import fieldMixin from "@/vform/components/form-designer/form-widget/field-widget/fieldMixin";
import Viewer from "viewerjs";
import "viewerjs/dist/viewer.css";
import { uploadServer } from "@/api/dss/fastdfsServer.js";
export default {
    name: "picture-upload-widget",
    componentName: "FieldWidget", //必须固定为FieldWidget，用于接收父级组件的broadcast事件
    mixins: [emitter, fieldMixin, i18n],
    props: {
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designer: Object,

        designState: {
            type: Boolean,
            default: false,
        },

        subFormRowIndex: {
            /* 子表单组件行索引，从0开始计数 */ type: Number,
            default: -1,
        },
        subFormColIndex: {
            /* 子表单组件列索引，从0开始计数 */ type: Number,
            default: -1,
        },
        subFormRowId: {
            /* 子表单组件行Id，唯一id且不可变 */ type: String,
            default: "",
        },
    },
    components: {
        FormItemWrapper,
    },
    inject: ["refList", "globalOptionData", "globalModel"],
    data() {
        return {
            gallery: null,
            oldFieldValue: null, //field组件change之前的值
            fieldModel: [],
            rules: [],

            uploadHeaders: {
                Authorization: getAccessToken(),
            },
            uploadData: {
                key: "", //七牛云上传文件名
                //token: '',  //七牛云上传token

                //policy: '',  //又拍云上传policy
                //authorization: '',  //又拍云上传签名
            },
            fileList: [], //上传文件列表

            previewUrl: "",
            showPreviewDialogFlag: false,
            el_upload_list: null,
            accept: ".jpg,.png",
            diyUploadShow: true,
            uploadList: [],
        };
    },
    computed: {
        realUploadURL() {
            let uploadURL = this.field.options.uploadURL;

            if (
                !!uploadURL &&
                (uploadURL.indexOf("DSV.") > -1 ||
                    uploadURL.indexOf("DSV[") > -1)
            ) {
                let DSV = this.getGlobalDsv();
                console.log("test DSV: ", DSV); //防止DSV被打包工具优化！！！
                return evalFn(this.field.options.uploadURL, DSV);
            }

            return process.env.VUE_APP_BASE_API + this.field.options.uploadURL;
        },

        uploadBtnHidden() {
            return (
                !this.fileList ||
                this.fileList.length >= this.field.options.limit
            );
        },
    },
    watch: {
        fileList: {
            handler(val) {},
            deep: true,
            immediate: true,
        },
        field: {
            handler(val) {
                if (
                    val != null &&
                    val.options.uploadURL != undefined &&
                    val.options.uploadURL != "" &&
                    !val.options.uploadURL.startsWith("?public=")
                ) {
                    this.diyUploadShow = false;
                } else {
                    this.diyUploadShow = true;
                }
                if (val.options && val.options.fileTypes) {
                    val.options.fileTypes.forEach((item, index) => {
                        if (index === 0) {
                            this.accept = "." + item;
                        } else {
                            this.accept = this.accept + ",." + item;
                        }
                    });
                }
            },
            deep: true,
            immediate: true,
        },
    },
    beforeCreate() {
        /* 这里不能访问方法和属性！！ */
    },

    created() {
        /* 注意：子组件mounted在父组件created之后、父组件mounted之前触发，故子组件mounted需要用到的prop
     需要在父组件created中初始化！！ */
        this.registerToRefList();
        this.initFieldModel();
        this.initEventHandler();
        this.buildFieldRules();

        this.handleOnCreated();
        // console.log(this.field.options.uploadURL);
    },

    mounted() {
        this.handleOnMounted();
    },

    beforeDestroy() {
        this.unregisterFromRefList();
    },

    methods: {
        getFileTypes(item) {
            return item.join("、");
        },
        handlePictureExceed() {
            let uploadLimit = this.field.options.limit;
            this.$message.warning(
                this.i18nt("render.hint.uploadExceed").replace(
                    "${uploadLimit}",
                    uploadLimit
                )
            );
        },

        handlePicturePreview(file) {
            let list = document.querySelectorAll(
                ".el-upload-list--picture-card"
            );
            for (let i = 0; i < list.length; i++) {
                let arr = list[i].querySelectorAll("img");
                for (let j = 0; j < arr.length; j++) {
                    if (decodeURI(arr[j].currentSrc) == file.url) {
                        this.gallery = new Viewer(list[i]);
                        this.gallery.update();

                        this.gallery.view(
                            this.fileList.findIndex(
                                (item) => item.url === file.url
                            )
                        );
                        return;
                    }
                }
            }
            // if (document.querySelector(".el-upload-list")) {
            //     this.gallery.update();

            //     this.gallery.view(
            //         this.fileList.findIndex((item) => item.url === file.url)
            //     );
            //     console.log("测试");
            //     // this.gallery.show();
            // } else {
            //     this.previewUrl = file.url;
            //     this.showPreviewDialogFlag = true;
            // }
        },

        beforePictureUpload(file) {
            let fileTypeCheckResult = false;
            if (!!this.field.options && !!this.field.options.fileTypes) {
                let uploadFileTypes = this.field.options.fileTypes;
                if (uploadFileTypes.length > 0) {
                    fileTypeCheckResult = uploadFileTypes.some((ft) => {
                        return file.type === "image/" + ft;
                    });
                }
            }
            if (!fileTypeCheckResult) {
                this.$message.error(
                    this.i18nt("render.hint.unsupportedFileType") + file.type
                );
                return false;
            }

            let fileSizeCheckResult = false;
            let uploadFileMaxSize = 5; //5MB
            if (!!this.field.options && !!this.field.options.fileMaxSize) {
                uploadFileMaxSize = this.field.options.fileMaxSize;
            }
            fileSizeCheckResult = file.size / 1024 / 1024 <= uploadFileMaxSize;
            if (!fileSizeCheckResult) {
                this.$message.error(
                    this.i18nt("render.hint.fileSizeExceed") +
                        uploadFileMaxSize +
                        "MB"
                );
                return false;
            }

            this.uploadData.key = file.name;
            return this.handleOnBeforeUpload(file);
        },

        //自定义上传
        uploadDss(request) {
            if (
                this.field.options.uploadURL != "?public=1" &&
                this.field.options.uploadURL != "?public=2"
            ) {
                this.$message.error({
                    message: "请检查上传地址配置是否正确！",
                    duration: 2000,
                });
                return;
            }

            // 默认需要登录
            let filePublic = "2";
            if (this.field.options.uploadURL.indexOf("?public=1") != -1) {
                filePublic = "1";
            }

            let file = request.file;
            let tokenReq = {
                platform: "jpgfast",
                type: "general",
                source: "system",
                public: filePublic,
            };
            uploadServer(tokenReq, file).then((resp) => {
                if (resp.code == 0) {
                    this.fieldModel = this.fieldModel || [];
                    let oldValue = deepClone(this.fileList);
                    this.fileList.push({
                        url: resp.data.url,
                        name: resp.data.filename,
                        hash:resp.data.hash
                    });
                    this.fieldModel = deepClone(this.fileList);
                    this.syncUpdateFormModel(this.fieldModel);
                    this.emitFieldDataChange(this.fieldModel, oldValue);
                    this.$emit("uploadSuccess", this.fileList);
                } else {
                    this.$message.error({
                        message: "图片上传失败,原因：" + resp.msg,
                        duration: 2000,
                    });
                }
            });

            // let parm = {
            //     storage: "MinIO",
            //     bucket: "jpgfast",
            //     pid: "1",
            //     resource: "public/uploads",
            //     action: "addFile",
            //     appid: "1",
            //     filehash: Date.parse(new Date()),
            //     filename: "keyName",
            //     ispub: "2",
            //     source: "3", //1:后台管理，2：个人文件，3，富文本等其他
            // };
            // uploadServer(request.file, parm).then((resp) => {
            //     if (resp.code === 0) {
            //         this.fileList.push({ url:resp.data.url });
            //         this.$emit("uploadSuccess", this.fileList);
            //     } else {
            //         this.$message.error({
            //             message: "图片上传失败,请重新上传",
            //             duration: 2000,
            //         });
            //     }
            // });
        },

        handleOnBeforeUpload(file) {
            if (!!this.field.options.onBeforeUpload) {
                let bfFunc = new Function(
                    "file",
                    this.field.options.onBeforeUpload
                );
                let result = bfFunc.call(this, file);
                if (typeof result === "boolean") {
                    return result;
                } else {
                    return true;
                }
            }

            return true;
        },

        updateFieldModelAndEmitDataChangeForUpload(
            fileList,
            customResult,
            defaultResult
        ) {
            this.fieldModel = this.fieldModel || [];
            let oldValue = deepClone(this.fieldModel);
            if (
                !!customResult &&
                !!customResult.name &&
                !!customResult.url &&
                !!customResult.hash
            ) {
                this.fieldModel.push({
                    name: customResult.name,
                    url: customResult.url,
                    hash: customResult.hash,
                });
            } else if (
                !!defaultResult &&
                !!defaultResult.name &&
                !!defaultResult.url &&
                !!defaultResult.hash
            ) {
                this.fieldModel.push({
                    name: defaultResult.name,
                    url: defaultResult.url,
                    hash: defaultResult.hash,
                });
            } else {
                this.fieldModel = deepClone(fileList);
            }

            this.syncUpdateFormModel(this.fieldModel);
            this.emitFieldDataChange(this.fieldModel, oldValue);
        },

        handlePictureUpload(res, file, fileList) {
            if (file.status === "success") {
                if (res.code === 0) {
                    file.url = res.data.url;
                }
                let customResult = null;
                if (!!this.field.options.onUploadSuccess) {
                    let customFn = new Function(
                        "result",
                        "file",
                        "fileList",
                        this.field.options.onUploadSuccess
                    );
                    customResult = customFn.call(this, res, file, fileList);
                }

                this.updateFieldModelAndEmitDataChangeForUpload(
                    fileList,
                    customResult,
                    res
                );
                this.fileList = deepClone(fileList);
                // if (this.fileList.length === 1) {

                // if (document.querySelector(".el-upload-list")) {
                //     this.gallery = new Viewer(
                //         document.querySelector(".el-upload-list")
                //     );
                // }

                this.$nextTick(() => {
                    this.clearValidate();
                });
            }
        },

        updateFieldModelAndEmitDataChangeForRemove(file, fileList) {
            let oldValue = deepClone(this.fieldModel);
            let foundFileIdx = -1;
            this.fileList.map((fi, idx) => {
                if (
                    fi.name === file.name &&
                    (fi.url === file.url || (!!fi.uid && fi.uid === file.uid))
                ) {
                    /* 这个判断有问题？？ */
                    foundFileIdx = idx;
                }
            });
            if (foundFileIdx > -1) {
                this.fieldModel.splice(foundFileIdx, 1);
            }

            this.syncUpdateFormModel(this.fieldModel);
            this.emitFieldDataChange(this.fieldModel, oldValue);
        },

        handlePictureRemove(file, fileList) {
            this.updateFieldModelAndEmitDataChangeForRemove(file, fileList);
            this.fileList = deepClone(fileList);

            if (!!this.field.options.onFileRemove) {
                let customFn = new Function(
                    "file",
                    "fileList",
                    this.field.options.onFileRemove
                );
                customFn.call(this, file, fileList);
            }
        },

        handleUploadError(err, file, fileList) {
            if (!!this.field.options.onUploadError) {
                let customFn = new Function(
                    "error",
                    "file",
                    "fileList",
                    this.field.options.onUploadError
                );
                customFn.call(this, err, file, fileList);
            } else {
                this.$message({
                    message: this.i18nt("render.hint.uploadError") + err,
                    duration: 3000,
                    type: "error",
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/global.scss"; //* form-item-wrapper已引入，还需要重复引入吗？ *//

.full-width-input {
    width: 100% !important;
}

.hideUploadDiv {
    ::v-deep div.el-upload--picture-card {
        /* 隐藏最后的图片上传按钮 */
        display: none;
    }

    ::v-deep div.el-upload--text {
        /* 隐藏最后的文件上传按钮 */
        display: none;
    }

    ::v-deep div.el-upload__tip {
        /* 隐藏最后的文件上传按钮提示 */
        display: none;
    }
}
</style>
