import { render, staticRenderFns } from "./container-item-wrapper.vue?vue&type=template&id=7f47fff8&scoped=true&"
import script from "./container-item-wrapper.vue?vue&type=script&lang=js&"
export * from "./container-item-wrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f47fff8",
  null
  
)

export default component.exports