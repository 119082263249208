<template>
    <form-item-wrapper
        :designer="designer"
        :field="field"
        :rules="rules"
        :design-state="designState"
        :parent-widget="parentWidget"
        :parent-list="parentList"
        :index-of-parent-list="indexOfParentList"
        :sub-form-row-index="subFormRowIndex"
        :sub-form-col-index="subFormColIndex"
        :sub-form-row-id="subFormRowId"
    >
        <el-select
            ref="fieldEditor"
            v-model="valueTitle"
            v-show="!isReadMode"
            :disabled="field.options.disabled"
            :clearable="field.options.clearable"
            :filterable="field.options.filterable"
            :allow-create="field.options.allowCreate"
            :default-first-option="allowDefaultFirstOption"
            :automatic-dropdown="field.options.automaticDropdown"
            :multiple="field.options.multiple"
            :multiple-limit="field.options.multipleLimit"
            :placeholder="
                field.options.placeholder ||
                i18nt('render.hint.selectPlaceholder')
            "
            :remote="field.options.remote"
            :remote-method="remoteMethod"
            @focus="handleFocusCustomEvent"
            @blur.native.capture="handleBlurCustomEvent"
            @change="handleChangeEvent"
            @clear="clearHandle"
        >
            <el-option :value="fieldModel" :label="fieldModel" class="options">
                <el-tree
                    id="tree-option"
                    ref="selectTree"
                    :accordion="accordion"
                    :data="optionData"
                    :show-checkbox="showCheckbox"
                    :props="props"
                    highlight-current
                    :node-key="props.value"
                    :default-expanded-keys="defaultExpandedKey"
                    @check-change="handleCheckChange"
                    @node-click="handleNodeClick"
                >
                </el-tree>
            </el-option>
        </el-select>
        <template v-if="isReadMode">
            <span class="readonly-mode-field">{{ optionLabel }}</span>
        </template>
    </form-item-wrapper>
</template>

<script>
import FormItemWrapper from "./form-item-wrapper";
import emitter from "@/vform/utils/emitter";
import i18n, { translate } from "@/vform/utils/i18n";
import fieldMixin from "@/vform/components/form-designer/form-widget/field-widget/fieldMixin";

export default {
    name: "tree-select-widget",
    componentName: "FieldWidget", //必须固定为FieldWidget，用于接收父级组件的broadcast事件
    mixins: [emitter, fieldMixin, i18n],
    props: {
        field: Object,
        parentWidget: Object,
        parentList: Array,
        indexOfParentList: Number,
        designer: Object,

        designState: {
            type: Boolean,
            default: false,
        },

        subFormRowIndex: {
            /* 子表单组件行索引，从0开始计数 */ type: Number,
            default: -1,
        },
        subFormColIndex: {
            /* 子表单组件列索引，从0开始计数 */ type: Number,
            default: -1,
        },
        subFormRowId: {
            /* 子表单组件行Id，唯一id且不可变 */ type: String,
            default: "",
        },

        /* 配置项 */
        props: {
            type: Object,
            default: () => {
                return {
                    value: "id", // ID字段名
                    label: "label", // 显示名称
                    children: "children", // 子级字段名
                };
            },
        },
        /* 选项列表数据(树形结构的对象数组) */
        data: {
            type: Array,
            default: () => {
                return [];
            },
        },
        /* 选项列表数据(树形结构的对象数组) */
        list: {
            type: Array,
            default: () => {
                return null;
            },
        },
        /* 初始值 */
        value: {
            type: String,
            default: () => {
                return null;
            },
        },
        /* 初始值 */
        url: {
            type: String,
            default: () => {
                return null;
            },
        },
        disabled: {
            type: Boolean,
            dafault: () => {
                return false;
            },
        },
        showCheckbox: {
            type: Boolean,
            dafault: () => {
                return false;
            },
        },
        /* 初始值 */
        textlabel: {
            type: String,
            default: () => {
                return null;
            },
        },
        /* 可清空选项 */
        clearable: {
            type: Boolean,
            default: () => {
                return true;
            },
        },
        /* 自动收起 */
        accordion: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
        size: {
            type: String,
            default: () => {
                return "small";
            },
        },
        placeholder: {
            type: String,
            default: () => {
                return "请选择";
            },
        },
        isOnlySelectLeaf: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
    },
    components: {
        FormItemWrapper,
    },
    inject: ["refList", "globalOptionData", "globalModel"],
    data() {
        return {
            valueId: this.value, // 初始值
            fieldModel: this.textlabel,
            defaultExpandedKey: [],
            placeholderText: this.placeholder,
            treeList: [],
            valueData: this.field.options.treeData,
            // fieldModel: null,
            rules: [],
            valueTitle: "",
        };
    },

    computed: {
        allowDefaultFirstOption() {
            return (
                !!this.field.options.filterable || !!this.field.options.remote
            );
        },

        remoteMethod() {
            if (
                !!this.field.options.remote &&
                !!this.field.options.onRemoteQuery
            ) {
                return this.remoteQuery;
            } else {
                return undefined;
            }
        },
        optionData() {
            if (this.list) {
                let cloneData = JSON.parse(JSON.stringify(this.list)); // 对源数据深度克隆
                return cloneData.filter((father) => {
                    // 循环所有项，并添加children属性
                    let branchArr = cloneData.filter(
                        (child) => father.id === child.parentId
                    ); // 返回每一项的子级数组
                    // eslint-disable-next-line no-unused-expressions
                    branchArr.length > 0 ? (father.children = branchArr) : ""; // 给父级添加一个children属性，并赋值
                    return father.parentId === "0"; // 返回第一层
                });
            } else {
                return this.field.options.treeData;
            }
        },
    },
    beforeCreate() {
        /* 这里不能访问方法和属性！！ */
    },

    created() {
        /* 注意：子组件mounted在父组件created之后、父组件mounted之前触发，故子组件mounted需要用到的prop
         需要在父组件created中初始化！！ */
        this.registerToRefList();
        this.initOptionItems();
        this.initFieldModel();
        this.initEventHandler();
        this.buildFieldRules();

        this.handleOnCreated();
        this.getInit();
    },

    mounted() {
        this.handleOnMounted();
    },

    beforeDestroy() {
        this.unregisterFromRefList();
    },
    methods: {
        getInit() {
            if (this.url !== null) {
                this.placeholderText = "加载数据中...";
                let interval = setInterval(() => {
                    this.placeholderText = this.placeholderText + ".";
                }, 500);
                this.$http({
                    url: this.url,
                    method: "get",
                }).then(({ data }) => {
                    this.valueData = data.treeData;
                    this.setTreeList(this.valueData);
                    this.$nextTick(() => {
                        this.initHandle();
                        this.placeholderText = this.placeholder;
                        clearInterval(interval);
                    });
                });
            } else {
                this.valueData = this.data;
                this.setTreeList(this.valueData);
                setTimeout((_) => {
                    this.initHandle();
                }, 300);
            }
        },
        setTreeList(datas) {
            // 遍历树  获取id数组
            for (var i in datas) {
                this.treeList.push(datas[i]);
                if (datas[i].children) {
                    this.setTreeList(datas[i].children);
                }
            }
        },
        // 初始化值
        initHandle() {
            if (this.valueId) {
                if (this.showCheckbox) {
                    let ids = this.valueId.split(",");
                    this.$refs.selectTree.setCheckedKeys(ids);
                    let titles = [];
                    ids.forEach((id) => {
                        this.treeList.forEach((d) => {
                            if (id === d[this.props.value]) {
                                titles.push(d[this.props.label]);
                            }
                        });
                    });

                    this.fieldModel = titles.join(",");
                } else if (this.$refs.selectTree.getNode(this.valueId)) {
                    this.fieldModel = this.$refs.selectTree.getNode(
                        this.valueId
                    ).data[this.props.label]; // 初始化显示
                    this.$refs.selectTree.setCurrentKey(this.valueId); // 设置默认选中
                    this.defaultExpandedKey = [this.valueId]; // 设置默认展开
                }
            }
            this.initScroll();
        },
        getNode(id) {
            return this.$refs.selectTree.getNode(id);
        },
        // 初始化滚动条
        initScroll() {
            this.$nextTick(() => {
                let scrollWrap = document.querySelectorAll(
                    ".el-scrollbar .el-select-dropdown__wrap"
                )[0];
                let scrollBar = document.querySelectorAll(
                    ".el-scrollbar .el-scrollbar__bar"
                );
                if (scrollWrap) {
                    scrollWrap.style.cssText =
                        "margin: 0px; max-height: none; overflow: hidden;";
                }
                if (scrollBar) {
                    scrollBar.forEach((ele) => {
                        // eslint-disable-next-line no-return-assign
                        return (ele.style.width = 0);
                    });
                }
            });
        },
        // 切换选项
        handleNodeClick(node) {
            if (this.showCheckbox) {
                return;
            }
            if (node["disabled"]) {
                this.$message.warning(
                    "节点（" +
                        node[this.props.label] +
                        "）被禁止选择，请重新选择。"
                );
                return;
            }
            if (this.isOnlySelectLeaf && node.children.length > 0) {
                this.$message.warning(
                    "不能选择根节点（" +
                        node[this.props.label] +
                        "）请重新选择。"
                );
                return;
            }
            this.fieldModel = node[this.props.label];
            this.valueId = node[this.props.value];
            this.valueTitle = this.fieldModel;
            this.handleChangeEvent(this.valueId )
        },
        handleCheckChange(data, checked, indeterminate) {
            let nodes = this.$refs.selectTree.getCheckedNodes();
            this.fieldModel = nodes
                .map((node) => {
                    return node[this.props.label];
                })
                .join(",");
            this.valueId = nodes
                .map((node) => {
                    return node[this.props.value];
                })
                .join(",");
            this.valueTitle = this.fieldModel;
            this.handleChangeEvent(this.valueId )
        },
        // 清除选中
        clearHandle() {
            this.fieldModel = "";
            this.valueId = null;
            this.defaultExpandedKey = [];
            this.clearSelected();
            this.$emit("getValue", null, null, null);
            this.$emit("input", null);
        },
        /* 清空选中样式 */
        clearSelected() {
            let allNode = document.querySelectorAll(
                "#tree-option .el-tree-node"
            );
            allNode.forEach((element) =>
                element.classList.remove("is-current")
            );
        },
        /**
         * 获取选中项label
         * @return {*}
         */
        getSelectedLabel() {
            return this.$refs.fieldEditor.selectedLabel;
        },
    },
    watch: {
        value() {
            // console.log("watch value",value)
            this.valueId = this.value;
            if (
                this.value === "" ||
                this.value === null ||
                this.value === undefined
            ) {
                this.clearHandle();
            } else {
                this.initHandle();
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-select {
    width: 100%;
}
.el-scrollbar .el-scrollbar__view .el-select-dropdown__item {
    height: auto;
    max-height: 274px;
    padding: 0;
    overflow: hidden;
    overflow-y: auto;
}
.el-select-dropdown__item.selected {
    font-weight: normal;
}
ul li >>> .el-tree .el-tree-node__content {
    height: auto;
    padding: 0 20px;
}
.el-tree-node__label {
    font-weight: normal;
}
.el-tree >>> .is-current .el-tree-node__label {
    color: #409eff;
    font-weight: 700;
}
.el-tree >>> .is-current .el-tree-node__children .el-tree-node__label {
    color: #606266;
    font-weight: normal;
}
/* 开发禁用 */
/* .el-tree-node:focus>.el-tree-node__content{
    background-color:transparent;
    background-color: #f5f7fa;
    color: #c0c4cc;
    cursor: not-allowed;
  }
  .el-tree-node__content:hover{
    background-color: #f5f7fa;
  } */
</style>