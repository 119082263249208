import request from '@/utils/request'

// 查询角色列表
export function listRole(query) {
  return request({
    url: '/role/list',
    method: 'get',
    params: query
  })
}

// 查询角色详细
export function getRole(roleId) {
  return request({
    url: '/role/get?id=' + roleId,
    method: 'get'
  })
}

//新增角色选项
export function addRoleOption(query) {
  return request({
    url: '/system/auth/addRole',
    method: 'get'
  })
}

// 新增角色
export function addRole(data) {
  return request({
    url: '/role/add',
    method: 'post',
    data: data
  })
}

// 修改角色
export function updateRole(data) {
  return request({
    url: '/role/edit',
    method: 'post',
    data: data
  })
}

// 角色数据权限
export function dataScope(data) {
  return request({
    url: '/role/edit',
    method: 'post',
    data: data
  })
}

// 角色状态修改
export function changeRoleStatus(roleId, status) {
  const data = {
    id: roleId,
    status: status
  }
  return request({
    url: '/role/changeStatus',
    method: 'post',
    data: data
  })
}

// 删除角色
export function delRole(roleId) {
  return request({
    url: '/role/del',
    method: 'post',
    data:{ids:roleId}
  })
}

// 导出角色
export function exportRole(query) {
  return request({
    url: '/system/role/export',
    method: 'get',
    params: query
  })
}

// 删除角色用户
export function addRoleUser(roleId, userIds) {
  return request({
    url: '/role/addUser',
    method: 'post',
    data:{id: roleId, user_ids:userIds}
  })
}

// 删除角色用户
export function delRoleUser(roleId, userIds) {
  return request({
    url: '/role/delUser',
    method: 'post',
    data:{id: roleId, user_ids:userIds}
  })
}