<template>
    <div>
        <div
            class="input-box"
            :class="{ 'as-input': buttonType === 'input' }"
            @click="!disabled ? (show = true) : ''"
        >
            <el-tag
                class="org-tag"
                :closable="!disabled"
                v-for="item in selectedData"
                :key="item.key"
                @close="!disabled && onClose(item)"
            >
                {{ item.label }}
            </el-tag>
        </div>

        <fc-org-transfer
            ref="transfer"
            v-bind="tagConfig"
            :value="fieldModel"
            :title="title"
            :searchable="true"
            :maxNum="maxNum"
            :tabList="tabList"
            :show.sync="dialogVisible"
            @confirm="onConfirm"
        />
    </div>
</template>
  
  <script>
  import fcOrgTransfer from '@/components/form/FormControls/OrgTransfer/index'
export default {
    name: "jf-select",
    components:{
        fcOrgTransfer
    },
    props: {
        value: {
            type: Object | null,
            required: true,
        },
        tabList: {
            type: Array,
            default: () => ["dep"],
        },
        title: {
            type: String,
            default: "组织机构",
        },
        buttonType: {
            type: String,
            default: "input",
        }, // or input
        searchable: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        maxNum: {
            type: Number,
            default: 99,
        },
        tagConfig: {
            type: Object,
            default: () => ({
                type: "info",
                closable: true,
                "disable-transitions": false,
                hit: false,
                color: undefined,
                size: "small",
                effect: "light",
            }),
        },
        show: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            fieldModel: null,

            dialogVisible: false,
            rules: [],
            tabKeys: [],
            selectedData: [],
        };
    },
    watch: {
        value: {
            handler: function (val) {
                if (!val) return;
                this.reloadCmpData();
            },
            immediate: true,
            deep: true,
        },
        tabList: {
            handler: function (val) {
                console.log(val, "tabList");
                this.tabKeys = [];
                val.forEach((t) =>
                    this.tabKeys.push(typeof t === "string" ? t : t.key)
                );
                this.reloadCmpData();
            },
            immediate: true,
        },
        show: {
            handler: function (val) {
                this.dialogVisible = val;
            },
            immediate: true,
            deep: true,
        },
        dialogVisible: {
            handler: function (val) {
                if (!val) {
                    this.$emit("update:show", false);
                }
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        selectedNum() {
            let num = 0;
            for (const key of this.tabKeys) {
                const data1 = this.selectedData[key];
                data1 && data1.length && (num += data1.length);
                const data2 = this.aloneCheckedData[key];
                data2 && data2.length && (num += data2.length);
            }
            return num;
        },
    },
    // beforeCreate() {
    //     /* 这里不能访问方法和属性！！ */
    // },

    // created() {
    //     /* 注意：子组件mounted在父组件created之后、父组件mounted之前触发，故子组件mounted需要用到的prop
    //        需要在父组件created中初始化！！ */
    //     /* 注意：子组件mounted在父组件created之后、父组件mounted之前触发，故子组件mounted需要用到的prop
    //      需要在父组件created中初始化！！ */
    //     this.registerToRefList();
    //     this.initEventHandler();

    //     this.handleOnCreated();
    // },

    // mounted() {
    //     this.handleOnMounted();
    //     this.getTabList();
    // },

    // beforeDestroy() {
    //     this.unregisterFromRefList();
    // },

    methods: {
        // getTabList() {
        //     this.tabKeys = [];
        //     this.tabList.forEach((t) =>
        //         this.tabKeys.push(typeof t === "string" ? t : t.key)
        //     );
        //     this.reloadCmpData();
        // },
        onConfirm(e) {
            this.fieldModel = e;
            this.initSelectedData();
            this.$emit("input", e);
        },
        reloadCmpData() {
            this.fieldModel = {};
            this.tabKeys.forEach((key) => {
                let data = {};
                if (this.value) {
                    data =
                        typeof this.value === "object"
                            ? this.value
                            : JSON.parse(this.value);
                }
                this.fieldModel[key] =
                    data && data[key]
                        ? JSON.parse(JSON.stringify(data[key]))
                        : [];
            });
            // transfer 可能还未加载成功
            this.$nextTick((_) => {
                this.initSelectedData();
            });
        },
        initSelectedData() {
            this.selectedData = this.tabKeys.reduce((res, key) => {
                return res.concat(
                    this.fieldModel[key].map((t) => ({
                        tabKey: key,
                        key: this.getKey(t, key),
                        label: this.getLabel(t, key),
                    }))
                );
            }, []);
        },
        getKey(data, tabKey) {
            return this.getPropByKey(data, tabKey, "nodeId");
        },

        getLabel(data, tabKey) {
            return this.getPropByKey(data, tabKey, "label");
        },
        getPropByKey(data, tabKey, key) {
            const transfer = this.$refs["transfer"];
            if (transfer) {
                return transfer.getNodeProp(data, key, tabKey);
            } else {
                return "";
            }
        },
        onClose(item) {
            const list = this.fieldModel[item.tabKey];
            const index = list.findIndex(
                (t) => this.getKey(t, item.tabKey) === item.key
            );
            index > -1 && list.splice(index, 1);
            this.initSelectedData();
            this.$emit("input", this.fieldModel);
        },
    },
};
</script>
  
  <style lang="scss" scoped>
</style>