<template>
  <grid-select 
    v-model="selectedValue" 
    :label="label" 
    :defaultLabel="defaultLabel"
    valueField="no"
    labelField="name"
    :placeholder="placeholder"
    :size="size" 
    :multiple="multiple"
    :searchItems="[{field: 'no', title: '工号'}, {field: 'name', title: '姓名'}, {field: 'dept_no', title: '院系', ctype: 'dept', valueField: 'no'}]"
    :gridItems="[{field: 'no', title: '工号'}, {field: 'name', title: '姓名'}, {field: 'dept_name', title: '院系'}]"
    :getDataList="listStuAssistant"
    @change="handleChange"
  ></grid-select>
</template>

<script>
import { listStuAssistant } from "@/api/stu/stu_assistant";

export default {
  name: 'assistant-select',
  props: {
    value: {
      type: [String, Number],
      default: () => { return null }
    },
    label: {
      type: String,
      default: () => { return null }
    },
    defaultLabel: {
      type: String,
      default: () => { return null }
    },
    placeholder: {
      type: String,
      default: () => { return '请选择' }
    },
    size: {
      type: String,
      default: () => { return 'medium' }
    },
    multiple: {
      type: Boolean,
      default: () => { return true }
    }
  },
  data() {
    return {
      listStuAssistant: listStuAssistant,
      selectedValue: this.value
    }
  },
  created() {
  },
  methods: {
    handleChange(e1, e2) {
      this.$emit('input', e1)
      this.$emit('change', e1, e2)
    }
  },
  watch: {
    value(val) {
      this.selectedValue = val
    }
  }
}
</script>
