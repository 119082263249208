<template>
  <form-item-wrapper
      :designer="designer"
      :field="field"
      :rules="rules"
      :design-state="designState"
      :parent-widget="parentWidget"
      :parent-list="parentList"
      :index-of-parent-list="indexOfParentList"
      :sub-form-row-index="subFormRowIndex"
      :sub-form-col-index="subFormColIndex"
      :sub-form-row-id="subFormRowId"
  >
    <vue-editor
        ref="fieldEditor"
        v-model="fieldModel"
        useCustomImageHandler
        @image-added="handleImageAdded"
        :editor-toolbar="customToolbar"
        :class="{ 'readonly-mode-rich-editor': isReadMode }"
        :disabled="field.options.disabled || isReadMode"
        :placeholder="field.options.placeholder"
        @text-change="handleRichEditorChangeEvent"
        @focus="handleRichEditorFocusEvent"
        @blur="handleRichEditorBlurEvent"
    >
    </vue-editor>
  </form-item-wrapper>
</template>

<script>
import FormItemWrapper from "./form-item-wrapper";
import { VueEditor } from "vue2-editor";
import emitter from "@/vform/utils/emitter";
import i18n, { translate } from "@/vform/utils/i18n";
import { deepClone } from "@/vform/utils/util";
import fieldMixin from "@/vform/components/form-designer/form-widget/field-widget/fieldMixin";
import {uploadServer} from "@/api/dss/fastdfsServer";
export default {
  name: "rich-editor-widget",
  componentName: "FieldWidget", //必须固定为FieldWidget，用于接收父级组件的broadcast事件
  mixins: [emitter, fieldMixin, i18n],
  props: {
    field: Object,
    parentWidget: Object,
    parentList: Array,
    indexOfParentList: Number,
    designer: Object,

    designState: {
      type: Boolean,
      default: false,
    },

    subFormRowIndex: {
      /* 子表单组件行索引，从0开始计数 */ type: Number,
      default: -1,
    },
    subFormColIndex: {
      /* 子表单组件列索引，从0开始计数 */ type: Number,
      default: -1,
    },
    subFormRowId: {
      /* 子表单组件行Id，唯一id且不可变 */ type: String,
      default: "",
    },
  },
  components: {
    FormItemWrapper,
    VueEditor,
    // VueEditor: resolve => { //懒加载！！
    //   require(['vue2-editor'], ({VueEditor}) => resolve(VueEditor))
    // }
  },
  inject: ["refList", "globalOptionData", "globalModel"],
  data() {
    return {
      oldFieldValue: null, //field组件change之前的值
      fieldModel: null,
      rules: [],

      customToolbar: [], //富文本编辑器自定义工具栏
      valueChangedFlag: false, //vue2-editor数据值是否改变标志
    };
  },
  computed: {
    //
  },
  watch: {
    fieldModel(val) {
      console.log(val);
    },
  },
  beforeCreate() {
    /* 这里不能访问方法和属性！！ */
  },

  created() {
    /* 注意：子组件mounted在父组件created之后、父组件mounted之前触发，故子组件mounted需要用到的prop
     需要在父组件created中初始化！！ */
    this.registerToRefList();
    this.initFieldModel();
    this.initEventHandler();
    this.buildFieldRules();

    this.handleOnCreated();
  },

  mounted() {
    this.handleOnMounted();
  },

  beforeDestroy() {
    this.unregisterFromRefList();
  },

  methods: {
    // 图片上传
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let tokenReq = {
        platform: "jpgfast",
        type: "editor",
        source: "system",
        public: "1"
      }
      uploadServer(tokenReq, file).then(resp => {
        if (resp.code == 0) {
          Editor.insertEmbed(cursorLocation, "image", resp.data.url);
        } else {
          this.$message.error({ message: '文件上传失败,请重新上传', duration: 2000 });
        }
      })
      resetUploader();
      // let parm = {
      //   storage: "MinIO",
      //   bucket: "jpgfast",
      //   pid:"1",
      //   resource: "public/uploads",
      //   action: "addFile",
      //   appid: "1",
      //   filehash: Date.parse(new Date()),
      //   filename : "keyName",
      //   ispub:'1',
      //   source:   "3",
      // };
      //
      // uploadServer(file, parm).then(resp => {
      //   if (resp.code === 0) {
      //     Editor.insertEmbed(cursorLocation, "image", this.apiUrl+resp.data.url);
      //   } else {
      //     this.$message.error({ message: '文件上传失败,请重新上传', duration: 2000 });
      //   }
      // });
      // resetUploader();
    },
    handleRichEditorChangeEvent() {
      this.valueChangedFlag = true;
      this.syncUpdateFormModel(this.fieldModel);
    },

    handleRichEditorFocusEvent() {
      this.oldFieldValue = deepClone(this.fieldModel);
    },

    handleRichEditorBlurEvent() {
      if (this.valueChangedFlag) {
        this.emitFieldDataChange(this.fieldModel, this.oldFieldValue);
        this.valueChangedFlag = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/global.scss"; //* form-item-wrapper已引入，还需要重复引入吗？ *//

.full-width-input {
  width: 100% !important;
}

.readonly-mode-rich-editor {
::v-deep .ql-toolbar {
  display: none;
}

::v-deep .ql-container {
//border-top: 1px solid #cccccc !important;
  border: 0;
}
}
</style>
