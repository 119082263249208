<template>
    <div id="tabs-bar-container" class="tabs-bar-container">
        <el-tabs
            v-model="tabActive"
            type="card"
            class="tabs-content"
            @tab-click="handleTabClick"
            @tab-remove="handleTabRemove"
        >
            <el-tab-pane
                v-for="item in visitedRoutes"
                :key="item.path"
                ref="tag"
                :label="
                    (item.query && item.query.title) ||
                    item.title ||
                    item.meta.title
                "
                :name="item.path + '-' + item.fullPath"
                :closable="!isAffix(item)"
            ></el-tab-pane>
        </el-tabs>
        <ul
            v-show="visible"
            :style="{ left: left + 'px', top: top + 'px' }"
            class="contextmenu"
        >
            <li @click="refreshSelectedTag(selectedTag)">
                <i class="el-icon-refresh-right"></i> 刷新页面
            </li>
            <li
                v-if="!isAffix(selectedTag)"
                @click="closeSelectedTag(selectedTag)"
            >
                <i class="el-icon-close"></i> 关闭当前
            </li>
            <li @click="closeOthersTags">
                <i class="el-icon-circle-close"></i> 关闭其他
            </li>
            <li @click="closeAlltabs(selectedTag)">
                <i class="el-icon-close"></i> 全部关闭
            </li>
            <li @click="collectMenu(selectedTag, true)" v-if="!isCollect">
                <i class="el-icon-star-off"></i> 添加收藏
            </li>
            <li @click="collectMenu(selectedTag, false)" v-if="isCollect">
                <i class="el-icon-star-on"></i> 取消收藏
            </li>
        </ul>
    </div>
</template>
  
<script>
import path from "path";
import { mapGetters } from "vuex";
import store from "@/store";
import { likeMenu, dislikeMenu } from "@/api/core/system/menu";
export default {
    name: "TabsBar",
    data() {
        return {
            affixtabs: [],
            tabActive: "",
            visible: false,
            isCollect: false,
            top: 0,
            left: 0,
            selectedTag: {},
            selectedRoute: {},
        };
    },

    computed: {
        ...mapGetters({
            visitedRoutes: "tabsBar/visitedRoutes",
            routes: "routes/routes",
            mainRoutes: "routes/mainRoutes",
            collects: "routes/collects",
        }),
    },
    watch: {
        $route: {
            handler(route) {
                // this.inittabs();
                this.addtabs();
                let tabActive = "";
                this.visitedRoutes &&
                    this.visitedRoutes.forEach((item, index) => {
                        if (item.path === this.$route.path) {
                            tabActive = item.path + "-" + item.fullPath;
                        }
                    });
                this.tabActive = tabActive;
                setTimeout(() => {
                    this.itemBindEvent();
                    this.moveToCurrentTag();
                }, 0);
            },
            immediate: true,
        },
        visible(value) {
            if (value) {
                document.body.addEventListener("click", this.closeMenu);
            } else {
                document.body.removeEventListener("click", this.closeMenu);
            }
        },
    },
    mounted() {
        //console.log(this.visitedRoutes);
        // 获取dom
        // let tab_top_dom = document.body.getElementsByClassName("el-tabs__header is-top")
        // console.log(tab_top_dom, 'tab_top_dom')
        // // 使用原生js 为单个dom绑定鼠标右击事件
        // tab_top_dom[0].openMenu = this.openMenu
        // this.itemBindEvent()
    },

    methods: {
        itemBindEvent() {
            this.$nextTick(() => {
                // 获取到所有的tab节点
                let tabsItem = document.body.querySelectorAll(
                    ".el-tabs__header .el-tabs__item"
                );
                // 给每个节点绑定对应的事件
                tabsItem.forEach((item) => {
                    item.addEventListener("contextmenu", (event) => {
                        // 阻止原生的菜单栏显示
                        event.preventDefault();
                        // 获取target
                        let target = event.target;
                        // 关闭按钮上点击右键找到父节点
                        if (event.target.className === "el-icon-close") {
                            target = event.target.parentNode;
                        }
                        // 获取tabs对应的模块名和路由名称
                        let routeName = target.getAttribute("id").split("-")[1];
                        let name = target.innerText;
                        // 展开自定义菜单栏
                        let index = this.visitedRoutes.findIndex(
                            (o) => o.path == routeName
                        );
                        let tag = {};
                        if (index > -1) {
                            tag = this.visitedRoutes[index];
                        }
                        this.openMenu(tag, event);
                    });
                });
            });
        },
        async handleTabRemove(tabActive) {
            let view;
            this.visitedRoutes.forEach((item, index) => {
                if (tabActive == item.path + "-" + item.fullPath) {
                    view = item;
                }
            });
            const { visitedRoutes } = await this.$store.dispatch(
                "tabsBar/delRoute",
                view
            );
            if (this.isActive(view)) {
                this.toLastTag(visitedRoutes, view);
            }
        },
        handleTabClick(tab) {
            console.log("点击tab", tab);
            const route = this.visitedRoutes.filter((item, index) => {
                if (tab.index == index) return item;
            })[0];
            if (this.$route.path !== route.path) {
                this.$router.push({
                    path: route.path,
                    query: route.query,
                    fullPath: route.fullPath,
                });
            } else {
                return false;
            }
        },
        isActive(route) {
            return route.path === this.$route.path;
        },
        isAffix(tag) {
            return tag.meta && tag.meta.affix;
        },
        filterAffixtabs(routes, basePath = "/") {
            let tabs = [];
            routes.forEach((route) => {
                if (route.meta && route.meta.affix) {
                    const tagPath = path.resolve(basePath, route.path);
                    tabs.push({
                        fullPath: tagPath,
                        path: tagPath,
                        name: route.name,
                        meta: { ...route.meta },
                    });
                }
                if (route.children) {
                    const temptabs = this.filterAffixtabs(
                        route.children,
                        route.path
                    );
                    if (temptabs.length >= 1) {
                        tabs = [...tabs, ...temptabs];
                    }
                }
            });
            return tabs;
        },
        inittabs() {
            const affixtabs = (this.affixtabs = this.filterAffixtabs(
                this.mainRoutes
            ));
            for (const tag of affixtabs) {
                if (tag.name) {
                    this.$store.dispatch("tabsBar/addVisitedRoute", tag);
                }
            }
        },
        getObjectByPath(path, data) {
            let result = null;

            function recursiveSearch(arr) {
                for (const item of arr) {
                    if (item.path === path) {
                        result = item;
                        break;
                    } else if (item.children && item.children.length) {
                        recursiveSearch(item.children);
                    }
                }
            }

            recursiveSearch(data);

            return result;
        },
        addtabs() {
            const { name, params, path, fullPath } = this.$route;
            let currentRoute = null;
            // 如果存在mid则查询当前路由对应的数据
            if (params && params.mid && process.env.NODE_ENV === "production") {
                currentRoute = this.getObjectByPath(path, this.routes);
            }

            if (name) {
                if (currentRoute) {
                    //深拷贝
                    let obj = JSON.parse(JSON.stringify(currentRoute));
                    this.$store.dispatch(
                        "tabsBar/addVisitedRoute",
                        Object.assign(obj, this.$route)
                    );
                    return;
                }
                this.$store.dispatch("tabsBar/addVisitedRoute", this.$route);
            }
            return false;
        },
        async closeSelectedTag(view) {
            const { visitedRoutes } = await this.$store.dispatch(
                "tabsBar/delRoute",
                view
            );
            if (this.isActive(view)) {
                this.toLastTag(visitedRoutes, view);
            }
        },
        async closeOtherstabs() {
            const view = await this.toThisTag();
            await this.$store.dispatch("tabsBar/delOthersRoutes", view);
        },
        async closeLefttabs() {
            const view = await this.toThisTag();
            await this.$store.dispatch("tabsBar/delLeftRoutes", view);
        },
        async closeRighttabs() {
            const view = await this.toThisTag();
            await this.$store.dispatch("tabsBar/delRightRoutes", view);
        },
        async closeAlltabs() {
            const view = await this.toThisTag();
            const { visitedRoutes } = await this.$store.dispatch(
                "tabsBar/delAllRoutes"
            );
            if (this.affixtabs.some((tag) => tag.path === view.path)) {
                return;
            }
            this.toLastTag(visitedRoutes, view);
        },
        toLastTag(visitedRoutes, view) {
            const latestView = visitedRoutes.slice(-1)[0];
            if (latestView) {
                this.$router.push(latestView);
            } else {
                this.$router.push("/");
            }
        },
        async toThisTag() {
            const view = this.visitedRoutes.filter((item, index) => {
                if (item.path === this.$route.fullPath) {
                    return item;
                }
            })[0];
            if (this.$route.path !== view.path) this.$router.push(view);
            return view;
        },
        moveToCurrentTag() {
            const tags = this.$refs.tag;
            this.$nextTick(() => {
                for (const tag of tags) {
                    let names = tag.name.split("-");
                    if (names[0] === this.$route.path) {
                        // when query is different then update
                        if (names[1] !== this.$route.fullPath) {
                            this.$store.dispatch(
                                "tabsBar/updateVisitedRoute",
                                this.$route
                            );
                        }
                        break;
                    }
                }
            });
        },
        refreshSelectedTag(view) {
            this.$store.dispatch("tabsBar/delCachedView", view).then(() => {
                const { path } = view;
                const { mid, enginetype } = view.query;
                if (path === "/form/model/formdesign") {
                    this.$nextTick(() => {
                        this.$router.replace({
                            path:
                                "/redirect" +
                                path +
                                (mid ? "?mid=" + mid : "") +
                                (enginetype ? "&enginetype=" + enginetype : ""),
                        });
                    });
                } else {
                    console.log(view, "view2");
                    this.$nextTick(() => {
                        this.$router.replace({
                            path: "/redirect" + path,
                        });
                    });
                }
            });
        },
        closeOthersTags() {
            this.$router.push(this.selectedTag);
            this.$store
                .dispatch("tabsBar/delOthersVisitedRoute", this.selectedTag)
                .then(() => {
                    this.moveToCurrentTag();
                });
        },
        findCollect(tag) {
            for (let key in this.collects) {
                let collect = this.collects[key];
                if (tag.path == collect.path) {
                    return collect;
                }
            }
            return null;
        },
        openMenu(tag, e) {
            this.$nextTick(() => {
                let collect = this.findCollect(tag);
                if (collect) {
                    this.isCollect = true;
                } else {
                    this.isCollect = false;
                }
            });
            // e.preventDefault(); //防止默认菜单弹出

            let obj = e.srcElement ? e.srcElement : e.target;
            const menuMinWidth = 105;
            const offsetLeft = this.$el.getBoundingClientRect().left; // container margin left
            const offsetWidth = this.$el.offsetWidth; // container width
            const maxLeft = offsetWidth - menuMinWidth; // left boundary
            const left = e.clientX - offsetLeft + 15; // 15; // 15: margin right
            if (left > maxLeft) {
                this.left = maxLeft;
            } else {
                this.left = left;
            }
            this.top = e.clientY - 60;
            this.visible = true;
            this.selectedTag = tag;
        },
        closeMenu() {
            this.visible = false;
            this.isCollect = false;
        },
        removeURLParameter(url, parameter) {
            let urlparts = url.split("?");
            if (urlparts.length >= 2) {
                //参数名前缀
                let prefix = encodeURIComponent(parameter) + "=";
                let pars = urlparts[1].split(/[&;]/g);

                //循环查找匹配参数
                for (let i = pars.length; i-- > 0; ) {
                    if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                        //存在则删除
                        pars.splice(i, 1);
                    }
                }
                return (
                    urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "")
                );
            }
            return url;
        },
        likeSelectedTag(view, currentRoute, rootRoute) {
            //收藏
            let data = {
                menu_id: currentRoute.id,
                root_id: rootRoute.id,
                root_title: rootRoute.name,
                title: (view.query && view.query.title) || view.title,
                icon: currentRoute.icon,
                path: this.removeURLParameter(view.fullPath, "_tool"),
                nav: "",
            };
            likeMenu(data).then((response) => {
                if (response.code === 0) {
                    this.$store.dispatch(
                        "routes/setCollects",
                        response.data.list
                    );
                    this.msgSuccess("收藏成功");
                } else {
                    this.msgError(response.msg);
                }
            });
        },
        dislikeSelectedTag(view, currentRoute, tagPath) {
            //取消收藏
            dislikeMenu(currentRoute.id, tagPath).then((response) => {
                if (response.code === 0) {
                    this.$store.dispatch(
                        "routes/setCollects",
                        response.data.list
                    );
                    this.msgSuccess("取消收藏成功");
                } else {
                    this.msgError(response.msg);
                }
            });
        },
        collectMenu(view, like = false) {
            this.selectedRoute = undefined;
            for (let key in this.routes) {
                let route = this.routes[key];
                if (route.children) {
                    this.findRoutePath(
                        route,
                        view.path,
                        route.children,
                        route.path,
                        view,
                        like
                    );
                    if (this.selectedRoute) {
                        break;
                    }
                }
            }
            if (!this.selectedRoute) {
                this.msgError("收藏失败");
            }
        },
        findRoutePath(rootRoute, route_path, routes, basePath, view, like) {
            for (let key in routes) {
                let route = routes[key];
                if (route.menu_type == "1") {
                    const tagPath = path.resolve(basePath, route.path);
                    if (tagPath == route_path) {
                        this.selectedRoute = route;
                        if (like) {
                            this.likeSelectedTag(view, route, rootRoute);
                        } else {
                            this.dislikeSelectedTag(view, route, tagPath);
                        }
                        return true;
                    }
                }
                if (route.children) {
                    this.findRoutePath(
                        rootRoute,
                        route_path,
                        route.children,
                        route.path,
                        view,
                        like
                    );
                }
            }
        },
    },
};
</script>
  
<style lang="scss" scoped>
.tabs-bar-container {
    position: relative;
    box-sizing: border-box;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    height: $base-tabs-bar-height;
    padding-right: $base-padding;
    padding-left: $base-padding;
    user-select: none;
    background: $base-color-white;
    border-top: 1px solid #f6f6f6;

    ::v-deep {
        .fold-unfold {
            margin-right: $base-padding;
        }
    }

    .tabs-content {
        width: calc(100% - 90px);
        height: $base-tag-item-height;

        ::v-deep {
            .el-tabs__nav-next,
            .el-tabs__nav-prev {
                height: $base-tag-item-height;
                line-height: $base-tag-item-height;
            }

            .el-tabs__header {
                border-bottom: 0;

                .el-tabs__nav {
                    border: 0;
                }

                .el-tabs__item {
                    box-sizing: border-box;
                    height: $base-tag-item-height;
                    // margin-top: 6px;
                    margin-right: 5px;
                    line-height: $base-tag-item-height;
                    /* border: 1px solid $base-border-color; */
                    border: none;
                    border-radius: $base-border-radius $base-border-radius 0 0;
                    transition: padding 0.3s
                        cubic-bezier(0.645, 0.045, 0.355, 1) !important;

                    &.is-active {
                        background-color: var(--column-menu-background);
                        color: var(--color-primary);
                        border: 1px solid var(--column-menu-background) !important;
                        border-bottom: 2px solid var(--color-primary) !important;
                    }
                }
            }
        }
    }

    .more {
        display: flex;
        align-content: center;
        align-items: center;
        cursor: pointer;
    }

    .contextmenu {
        margin: 0;
        background: #fff;
        z-index: 3000;
        position: absolute;
        list-style-type: none;
        padding: 5px 0;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #333;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);

        li {
            margin: 0;
            padding: 7px 16px;
            cursor: pointer;

            &:hover {
                background: #eee;
            }
        }
    }
}
</style>
  