import { render, staticRenderFns } from "./vf-dialog-item.vue?vue&type=template&id=40b22b97&scoped=true&"
import script from "./vf-dialog-item.vue?vue&type=script&lang=js&"
export * from "./vf-dialog-item.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b22b97",
  null
  
)

export default component.exports