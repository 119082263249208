<template>
    <el-dropdown @command="handleCommand">
        <span class="avatar-dropdown">
            <img class="user-avatar" :src="avatar" alt="" />
            <div class="user-name">
                {{ username }}
                <i class="el-icon-arrow-down el-icon--right"></i>
            </div>
        </span>

        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="personalCenter" icon="el-icon-user"
                >个人中心</el-dropdown-item
            >
            <el-dropdown-item
                v-if="permissions.length > 0 && permissions[0] == '*/*/*'"
                divided
                icon="el-icon-brush"
                @click.native="clearCache"
            >
                <span>清空缓存</span>
            </el-dropdown-item>
            <el-dropdown-item command="logout" divided icon="el-icon-right"
                >退出登录</el-dropdown-item
            >
        </el-dropdown-menu>
    </el-dropdown>
</template>

<script>
import { mapGetters } from "vuex";
import { clear } from '@/api/core/monitor/cache' 
import { recordRoute } from "@/config";
export default {
    name: "MyAvatar",
    computed: {
        ...mapGetters({
            avatar: "user/avatar",
            username: "user/username",
            permissions:"user/permissions"
        }),
    },
    methods: {
        handleCommand(command) {
            switch (command) {
                case "logout":
                    this.logout();
                    break;
                case "personalCenter":
                    this.personalCenter();
                    break;
            }
        },
        personalCenter() {
            this.$router.push("/personalCenter");
        },
        async clearCache() {
            this.$confirm("确定清空缓存吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                clear().then(() => {
                    if (process.env.VUE_APP_ENABLE_CAS === "true") {
                        const url =
                            process.env.VUE_APP_CAS_SERVER_URL + "/logout";
                        window.location.href = url;
                    } else {
                        location.reload();
                    }
                });
            });
        },
        logout() {
            this.$baseConfirm(
                "您确定要退出" + this.$baseTitle + "吗?",
                null,
                async () => {
                    await this.$store.dispatch("user/logout");
                    this.$router.push("/login");
                }
            );
        },
    },
};
</script>
<style lang="scss" scoped>
.avatar-dropdown {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    justify-items: center;
    height: 50px;
    padding: 0;

    .user-avatar {
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 50%;
    }

    .user-name {
        position: relative;
        margin-left: 5px;
        margin-left: 5px;
        cursor: pointer;
    }
}
</style>
