<template>
  <div class="animate-popover">
    <el-dialog
      :title="(content && content.title) || '提示'"
      :visible.sync="dialogVisible"
      top="0"
      :before-close="handleClose"
      :modal-append-to-body="false"
    >
      <div class="code-content">
        <el-image
          class="code-img"
          :src="require('@/assets/template/v2_qh7fef.jpg')"
        ></el-image>
        <div class="text">扫描二维码并关注公众号，即可开启扫码登录功能</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "MyAnimatePopover",
  data() {
    return {
      dialogVisible: false,
      animate: true,
      content: {},
    };
  },
  methods: {
    handleOpen(info) {
      this.dialogVisible = true;
      this.content = info;
      setTimeout(() => {
        this.animate = true;
      }, 10);
      setTimeout(() => {
        this.animate = false;
      }, 1000);
    },
    handleClose(done) {
      this.dialogVisible = false;
      setTimeout(() => {
        this.content = null;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.animate-popover {
  ::v-deep {
    .el-dialog {
      position: absolute;
      // 根据项目中弹窗的大小设定的居中，用其他方式实现居中也行
      left: calc(50% - 290px);
      top: calc(50% - 250px);
      overflow: hidden;
      width: 580px;
      height: 500px;
      &__title {
        color: $base-color-default;
        font-weight: bolder;
      }
      &__wrapper {
        &.dialog-fade-enter-active {
          -webkit-animation: flipInY 1s !important;
          animation: flipInY 1s !important;
        }
      }
    }
  }
}

.code-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.code-img {
  border: 1px solid $base-border-color;
  width: 258px;
  height: 258px;
}
.text {
  color: #000;
  font-weight: bolder;
  margin-top: 30px;
  font-size: 16px;
}
</style>