import request from '@/utils/request'

export function listGbpxTeacher(query) {
  return request({
    url: '/gbpx/gbpxteacher/list',
    method: 'get',
    params: query
  })
}

export function getGbpxTeacher(id) {
  return request({
    url: '/gbpx/gbpxteacher/get?id=' + id,
    method: 'get'
  })
}

export function addGbpxTeacher(data) {
  return request({
    url: '/gbpx/gbpxteacher/add',
    method: 'post',
    data: data
  })
}

export function editGbpxTeacher(data) {
  return request({
    url: '/gbpx/gbpxteacher/edit',
    method: 'post',
    data: data
  })
}

export function delGbpxTeacher(ids) {
  return request({
    url: '/gbpx/gbpxteacher/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

