import request from '@/utils/request'

export function listStuStudent(query) {
  return request({
    url: '/stu/stustudent/list',
    method: 'get',
    params: query
  })
}

export function getStuStudent(id, no) {
  return request({
    url: '/stu/stustudent/get?' + (id ? ('id=' + id) : ('no=' + no)),
    method: 'get'
  })
}

export function addStuStudent(data) {
  return request({
    url: '/stu/stustudent/add',
    method: 'post',
    data: data
  })
}

export function editStuStudent(data) {
  return request({
    url: '/stu/stustudent/edit',
    method: 'post',
    data: data
  })
}

export function delStuStudent(ids) {
  return request({
    url: '/stu/stustudent/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

export function syncAccount(query) {
  return request({
    url: '/stu/stustudent/syncAccount',
    method: 'post',
    data: query
  })
}

export function syncAccountProgress() {
  return request({
    url: '/stu/stustudent/syncAccountProgress',
    method: 'get'
  })
}
