<template>
    <div>
        <el-drawer
            title="主题配置"
            :visible="themeDrawerVisible"
            direction="rtl"
            append-to-body
            size="285px"
            @close="drawerClose"
            @open="drawerOpen"
        >
            <el-scrollbar style="height: 80vh; overflow: hidden">
                <div class="el-drawer__body">
                    <el-form
                        ref="form"
                        :model="theme"
                        label-position="left"
                        label-width="90px"
                    >
                        <el-form-item label="布局">
                            <el-select v-model="theme.layout">
                                <el-option
                                    label="分栏"
                                    value="column"
                                ></el-option>
                                <el-option
                                    label="综合"
                                    value="comprehensive"
                                ></el-option>
                                <el-option
                                    label="纵向"
                                    value="vertical"
                                ></el-option>
                                <el-option
                                    label="横向"
                                    value="horizontal"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="风格">
                            <el-select v-model="theme.themeMode">
                                <el-option
                                    v-for="item in themeModes"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="颜色">
                            <el-select
                                v-model="theme.themeColor"
                                @change="onThemeSelectorChange"
                            >
                                <el-option
                                    v-for="item in themes"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="自定义颜色">
                            <color-picker
                                class="item-custom"
                                v-model="customColor"
                                :predefine="themeList"
                                @change="handleChangeCustomColor"
                            >
                            </color-picker>
                        </el-form-item>
                        <el-form-item label="菜单背景">
                            <sider-background-picker
                                class="item-custom"
                                v-model="theme.siderBackgroundImg"
                            />
                            <!-- <el-radio-group v-model="theme.siderBackgroundImg" :disabled="layout==='horizontal'" class="item-custom">
                  <el-radio-button label="none" class="none" title="不设置背景"></el-radio-button>
                  <el-radio-button label="background" class="background"></el-radio-button>
                </el-radio-group> -->
                        </el-form-item>
                    </el-form>
                </div>
            </el-scrollbar>

            <div class="el-drawer__footer">
                <el-button type="primary" @click="handleSaveTheme"
                    >保存</el-button
                >
                <el-button @click="handleSetDfaultTheme">恢复默认</el-button>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import defaultConfig, { layout as defaultLayout, themeMode } from "@/config";
import { themeModes } from "./themeModes.js";
import SiderBackgroundPicker from "./components/SiderBackgroundPicker.vue";
export default {
    components: {
        SiderBackgroundPicker,
    },
    name: "ThemeDrawer",
    data() {
        return {
            theme: {
                name: "default",
                layout: defaultLayout,
                header: "fixed",
                tabsBar: true,
                themeMode: themeMode,
                themeColor: this.$store.state.settings.themeColor,
                siderBackgroundImg: "none",
            },
            themeModes,
            themeList: [
                "#FFFFFF",
                "#000000",
                "#54B9D0",
                "#051C70", // '#4A93EC',
                "#9176C8",
                "#AF6DC4",
                "#DE5D56",
                "#F19D37",
                "#F5C344",
                "#C1CA50",
                "#67AE58",
            ],
            customColor: "#FFFFFF",
        };
    },
    computed: {
        ...mapGetters({
            layout: "settings/layout",
            header: "settings/header",
            tabsBar: "settings/tabsBar",
            themeBar: "settings/themeBar",
            themeDrawerVisible: "settings/themeDrawerVisible",
            themeColor: "setting/themeColor",
        }),
        themes() {
            let { theme, themeModes } = this;
            let arr =
                (themeModes &&
                    themeModes.filter(
                        (item) => item.value == theme.themeMode
                    )) ||
                [];
            arr = JSON.parse(JSON.stringify((arr[0] && arr[0].themes) || []));
            if (theme.layout == "vertical" && theme.themeMode == "dark") {
                /*  arr.push(
					   {
					     label: '理工科风格',
					     value: '#035DB2'
					   }
					 ); */
                return arr.concat({
                    label: "理工科风格",
                    value: "#035DB2",
                });
            } else {
                if (this.theme.themeColor == "#035DB2") {
                    this.theme.themeColor = "#1890ff";
                }
                return arr;
            }
        },
    },
    watch: {
        theme: {
            handler(newVal) {
                this.handleSetTheme(false);
            },
            deep: true,
        },
        customColor: {
            handler(val) {
                localStorage.setItem("customColor", val);
            },
            deep: true,
        },
        layout: {
            handler(val) {
                this.theme.layout = val;
            },
            deep: true,
        },
    },
    created() {
        this.$baseEventBus.$on("theme", () => {
            this.handleOpenThemeBar();
        });
        const theme = localStorage.getItem("qz-micro-app1-theme");
        const customColor = localStorage.getItem("customColor");
        customColor ? (this.customColor = customColor) : null;
        if (null !== theme) {
            this.theme = JSON.parse(theme);
        } else {
            this.theme.layout = this.layout;
            this.theme.header = this.header;
            this.theme.tabsBar = this.tabsBar;
        }
        this.handleSetTheme(false);
    },
    methods: {
        ...mapActions({
            changeLayout: "settings/changeLayout",
            changeHeader: "settings/changeHeader",
            changeTabsBar: "settings/changeTabsBar",
            changeThemeDrawer: "settings/changeThemeDrawer",
            setThemeColor: "settings/setThemeColor",
            changeThemeMode: "settings/changeThemeMode",
            changeSiderBackgroundImg: "settings/changeSiderBackgroundImg",
        }),

        handleIsMobile() {
            return document.body.getBoundingClientRect().width - 1 < 992;
        },
        handleOpenThemeBar() {
            this.drawerOpen();
        },
        handleSetTheme(closeDrawer = true) {
            let {
                name,
                layout,
                header,
                tabsBar,
                themeMode,
                themeColor,
                siderBackgroundImg,
            } = this.theme;

            localStorage.setItem(
                "qz-micro-app1-theme",
                JSON.stringify(this.theme)
            );
            if (!this.handleIsMobile()) this.changeLayout(layout);
            this.changeHeader(header);
            this.changeTabsBar(tabsBar);
            this.customColor == "#FFFFFF"
                ? this.handleChangeColor(themeColor)
                : null;
            //设置主题
            let DOC = document.getElementById("app");
            if (!DOC) {
                //DOC = document.getElementById('mainContainer').firstChild.shadowRoot.getElementById('app')
                DOC = document
                    .getElementById("mainContainer")
                    .firstChild.getElementById("app");
            }
            DOC.setAttribute("theme-mode", themeMode);
            //DOC.classList.add("side-bar-background")
            if (siderBackgroundImg === "background2") {
                // this.customColor = "#de5d56";
                this.handleChangeCustomColor('#de5d56')
             
            }else if(siderBackgroundImg === "background"){
                // this.customColor = "#1890ff"; 
                this.handleChangeCustomColor('#1890ff')
               
            }
            DOC.setAttribute("background", siderBackgroundImg);
            this.changeSiderBackgroundImg(siderBackgroundImg);
            this.changeThemeMode(themeMode);
            closeDrawer ? this.drawerClose() : null;
        },
   
        handleSaveTheme() {
            this.handleSetTheme();
        },
        handleCancel() {
            this.drawerClose();
        },
        drawerOpen() {
            this.changeThemeDrawer(true);
        },
        drawerClose() {
            this.changeThemeDrawer(false);
        },
        handleSetDfaultTheme() {
            let { name } = this.theme;
            this.customColor = "#FFFFFF";
            localStorage.setItem("customColor", this.customColor);
            let DOC = document.getElementById("app");
            if (!DOC) {
                //在主应用中运行
                //DOC = document.getElementById('mainContainer').firstChild.shadowRoot.getElementById('app')
                DOC = document
                    .getElementById("mainContainer")
                    .firstChild.getElementById("app");
            }
            DOC.setAttribute("theme-mode", themeMode);
            //DOC.classList.remove("side-bar-background")
            DOC.setAttribute("background", "none");
            this.changeSiderBackgroundImg("none");
            this.changeThemeMode(themeMode);
            //document.getElementsByTagName('body')[0].classList.remove(`qz-micro-app1-theme-${name}`)

            localStorage.removeItem("qz-micro-app1-theme");
            this.$refs["form"].resetFields();

            const assign = (toObj, fromObj) => {
                let newObj = {};
                for (let k in toObj) {
                    newObj[k] = fromObj[k];
                }
                return newObj;
            };
            this.theme = assign(this.theme, defaultConfig);
            this.theme.siderBackgroundImg = "none";

            if (!this.handleIsMobile())
                this.changeLayout(defaultConfig.defaultLayout);
            this.changeHeader(defaultConfig.header);
            this.changeTabsBar(defaultConfig.tabsBar);
            this.handleChangeColor(defaultConfig.themeColor);

            this.drawerClose();
            //location.reload()
        },
        onThemeSelectorChange() {
            this.customColor = "#FFFFFF";
        },
        handleChangeColor(val) {
            this.setThemeColor(val);
        },
        handleChangeCustomColor(val) {
            localStorage.setItem("customColor", val);
            if (val === "#FFFFFF") {
                this.handleChangeColor(this.theme.themeColor);
                return false;
            }
            this.handleChangeColor(val);
        },
    },
};
</script>

<style lang="scss" scoped>
.el-drawer__body {
    padding: 20px;

    ::v-deep {
        .item-custom {
            display: block !important;
            min-height: 100px;
            float: left;
            margin-top: 34px;
            margin-left: -90px;
        }

        .el-radio-button {
            display: block;
            float: left;
            width: 80px;
            height: 80px;
            margin: 10px;
            cursor: pointer;
            border-radius: 5px;

            &.none {
                font-family: "iconfont", "element-icons" !important;
                font-size: 16px;
                font-weight: 580;
                line-height: 80px;
                text-align: center;
                background: #f7f7f7 none;
                background-size: cover;
            }

            &.none::before {
                content: "\e669";
            }

            &.background {
                background: url("../../../assets/theme/background-1.jpg");
                background-size: cover;
            }
            &.background2 {
                background: url("../../../assets/theme/background-2.png");
                background-size: cover;
            }

            &.is-active {
                box-shadow: 0 0 2px 2px #1890ff;
            }

            &.is-disabled {
                cursor: not-allowed;
                opacity: 0.6;
            }

            .el-radio-button__inner {
                display: none;
            }
        }
    }
}

.el-drawer__footer {
    border-top: 1px solid #dedede;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 0 0 20px;
    height: 50px;
    background: $base-color-white;
}

.el-drawer__wrapper {
    outline: none !important;

    * {
        outline: none !important;
    }
}

.el-drawer__header {
    margin-bottom: 0 !important;
}
</style>