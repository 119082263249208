<template>
  <div id="app">
    <GlobalMessage />
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <!-- <router-view/> -->
  </div>
</template>

<script>

import GlobalMessage from "@/components/GlobalMessage/index";
export default {
    components: {
    GlobalMessage,
  },
  mounted() {
            const is360CompatibleMode = /360\s{0,}(ee|se|chrome|firefox|opera|qq|metasr)\//i.test(navigator.userAgent);
            // this.showMessage = is360CompatibleMode;
            console.log(is360CompatibleMode,'is360CompatibleMode')
    // 注册观察者函数
    /* actions.onGlobalStateChange(state => {
      //console.log('子应用的观察函数：', state)
      const { methods, token } = state;
      switch(methods) {
        case 'login':
          this.handleTokenLogin(token)
          break;
        case 'logout':
          break;
      }
    }, true) */
  },
  methods: {
    /* handleTokenLogin(token) {
      this.$store.dispatch('user/tokenLogin', {token: token}).then(() => {
        //console.log('子应用token登录成功');
        this.$router.push('/').catch(() => {})
      }).catch((err) => {
        //console.log('子应用token登录失败',err);
        this.$router.push('/login').catch(() => {})
      })
    } */
  }
}
</script>

<style lang="scss" scoped></style>
