import request from '@/utils/request'

export function listStuMajor(query) {
  return request({
    url: '/stu/stumajor/list',
    method: 'get',
    params: query
  })
}

export function getStuMajor(id) {
  return request({
    url: '/stu/stumajor/get?id=' + id,
    method: 'get'
  })
}

export function addStuMajor(data) {
  return request({
    url: '/stu/stumajor/add',
    method: 'post',
    data: data
  })
}

export function editStuMajor(data) {
  return request({
    url: '/stu/stumajor/edit',
    method: 'post',
    data: data
  })
}

export function delStuMajor(ids) {
  return request({
    url: '/stu/stumajor/del',
    method: 'post',
    data: {
      ids: ids
    }
  })
}

