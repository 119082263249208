<template>
	<el-breadcrumb class="breadcrumb-container" separator=">">
		<el-breadcrumb-item v-for="item in list" :key="item.path">
			<!-- <i v-if="item.meta && item.meta.icon" :class="['iconfont', `icon-${item.meta.icon}`]" ></i> -->
			<icon-item :icon="item.meta.icon||(item.meta&&item.meta.icon)" />
			{{ item.meta.title }}
		</el-breadcrumb-item>
	</el-breadcrumb>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import IconItem from '../SideBar/components/Item'
	import path from 'path'
	export default {
		name: 'Breadcrumb',
		components: {
			IconItem
		},
		data() {
			return {
				list: [],
			}
		},
		computed: {
			...mapGetters({
				routes: 'routes/routes',
				mainRoutes: 'routes/mainRoutes'
			})
		},
		watch: {
			$route() {
				this.list = this.getBreadcrumb()
			},
		},
		mounted() {
			this.list = this.getBreadcrumb()
		},
		methods: {
			getBreadcrumb() {
				//  let { $route } = this;
				//  let matched = $route.matched.filter(item => item.name && item.meta.title)

				//  return matched
				let breadcrumbList = this.$store.state.routes.breadcrumbList
				let matched = breadcrumbList.filter(item => item.meta && item.meta.title)
				return matched
				const first = matched[0]
				// if (!this.isDashboard(first)) {
				// 	matched = [{
				// 		path: '/dashboards',
				// 		meta: {
				// 			title: '首页'
				// 		}
				// 	}].concat(matched)
				// },
			},
		},
	}
</script>

<style lang="scss" scoped>
	.breadcrumb-container {
		height: $base-nav-bar-height;
		font-size: $base-font-size-default;
		line-height: $base-nav-bar-height;

		::v-deep {
			.el-breadcrumb__item {
				.el-breadcrumb__inner {
					a {
						display: flex;
						float: left;
						font-weight: normal;
						color: #515a6e;

						i {
							margin-right: 3px;
						}
					}

					&.is-link {
						font-weight: 500;
						color: $base-color-black;
					}
				}

				&:last-child {
					.el-breadcrumb__inner {
						a {
							color: #999;
						}
					}
				}
			}
		}
	}
</style>