<template>
  <div class="nav-bar-container">
    <el-row :gutter="15">
      <el-col :xs="4" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="left-panel">
          <i
            :class="collapse ? 'icon-zhankai' : 'icon-shouqi'"
            :title="collapse ? '展开' : '收起'"
            class="iconfont fold-unfold"
            @click="handleCollapse"
          ></i>
          <comprehensive-nav v-if="'comprehensive' === layout" />
          <breadcrumb v-else class="hidden-xs-only" />
        </div>
      </el-col>
      <el-col :xs="20" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="right-panel">
          <back-to-main-app />
          <icon-badge
            iconName="el-icon-chat-dot-round"
            :value="msgUnread"
            :width="300"
          >
            <template>
              <div class="message-box">
                <el-table
                  :data="msgList"
                  :show-header="false"
                  max-height="260px"
                  @row-click="chatRowClick"
                >
                  <el-table-column
                    min-width="150"
                    show-overflow-tooltip
                    property="content"
                    label="内容"
                  ></el-table-column>
                  <el-table-column
                    min-width="100"
                    property="date"
                    label="日期"
                  ></el-table-column>
                </el-table>
                <el-divider v-if="msgList.length > 0"></el-divider>
                <el-button
                  v-if="msgList.length > 0"
                  type="text"
                  size="medium"
                  @click="handleReadAll"
                  >全部已读</el-button
                >
              </div>
            </template>
          </icon-badge>
          <icon-badge
            iconName="el-icon-document"
            :value="taskUnread"
            :width="300"
          >
            <template>
              <div class="message-box">
                <el-table
                  :data="taskList"
                  :show-header="false"
                  max-height="260px"
                  @row-click="rowClick"
                >
                  <el-table-column
                    min-width="150"
                    show-overflow-tooltip
                    property="content"
                    label="内容"
                  ></el-table-column>
                  <el-table-column
                    min-width="100"
                    property="date"
                    label="日期"
                  ></el-table-column>
                </el-table>
              </div>
            </template>
          </icon-badge>
          <theme-bar />
          <my-avatar />
        </div>
      </el-col>
    </el-row>
    <!-- 消息弹窗 -->
    <message-dialog ref="messageDialog"></message-dialog>
    <!-- 任务弹窗-->
    <view-dialog ref="viewDialog"></view-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { myMessage, readMessage } from "@/api/core/system/message";
import { myListOaTaskRecord, readOaTaskRecord } from "@/api/core/oa/oa_task_record";
// import FormDialog from "@/views/oa/oa_task/form-dialog";
import MessageDialog from "@/views/message/message-dialog";
import ViewDialog from "@/views/core/oa/oa_task_record/view-dialog";
import { EventBus } from "@/utils/eventBus.js";
export default {
  name: "NavBar",
  components: {
    "view-dialog": ViewDialog,
    "message-dialog": MessageDialog,
  },
  data() {
    return {
      pulse: false,
      msgList: [],
      msgUnread: undefined,
      taskList: [],
      taskUnread: undefined,
    };
  },
  created() {
    this.getMyMessage();
    this.getMyListOaTaskRecord();
  },
  mounted() {
    EventBus.$on("readMeg", (msg) => {
      if (msg === "readMessage") {
        this.getMyMessage();
      }
    });
    EventBus.$on("readTask", (msg) => {
      if (msg === "readOaTaskRecord") {
        this.getMyListOaTaskRecord();
      }
    });
  },
  computed: {
    ...mapGetters({
      collapse: "settings/collapse",
      visitedRoutes: "tabsBar/visitedRoutes",
      device: "settings/device",
      routes: "routes/routes",
      mainRoutes: "routes/mainRoutes",
      layout: "settings/layout",
    }),
    tabList() {
      let arr = [];
      arr =
        (this.mainRoutes && this.mainRoutes.filter((item) => !item.hidden)) ||
        [];
      return arr;
    },
  },
  methods: {
    ...mapActions({
      changeCollapse: "settings/changeCollapse",
    }),
    getMyMessage() {
      myMessage({ read_flag: "0", pageSize: 100 }).then((res) => {
        let msgUnread = res.data.unread;
        if (msgUnread == 0) {
          msgUnread = undefined;
        }
        this.msgUnread = msgUnread;
        const msgList = [];

        for (let i = 0; i < res.data.list.length; i++) {
          const d = res.data.list[i];
          msgList.push({
            id: d.id,
            title: d.title,
            date: this.parseTime(d.create_time, "{y}-{m}-{d}"),
            content: d.title + "：" + d.content,
            create_time: d.create_time,
          });
        }
        this.msgList = msgList;
      });
    },
    getMyListOaTaskRecord() {
      myListOaTaskRecord({ readed: "0" }).then((res) => {
        if(res.data.list==null){
            return;
        }
        let taskUnread = res.data.list.length;
        if (taskUnread == 0) {
          taskUnread = undefined;
        }
        this.taskUnread = taskUnread;
        const taskList = [];
        for (let i = 0; i < res.data.list.length; i++) {
          const d = res.data.list[i];
          taskList.push({
            id: d.id,
            date: this.parseTime(d.create_time, "{y}-{m}-{d}"),
            content: d.task && d.task.title || '',
            readed: d.readed,
          });
        }
        this.taskList = taskList;
      });
    },

    handleCollapse() {
      this.changeCollapse();
    },

    async refreshRoute() {
      this.$baseEventBus.$emit("reload-router-view");
      this.pulse = true;
      setTimeout(() => {
        this.pulse = false;
      }, 1000);
    },
    handleToMainApp() {},
    handleReadAll() {
      this.$confirm("确定设置全部已读吗?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        readMessage(0).then((res) => {
          this.msgSuccess("设置全部已读成功");
          this.msgUnread = undefined;
          this.msgList = [];
        });
      });
    },
    // 消息点击弹窗
    chatRowClick(row) {
      let info = {
        id: row.id,
        title: row.title,
        content: row.content.split("：")[1],
        create_time: row.create_time,
        read_flag: "0",
      };
      this.$refs.messageDialog.showView(info);
    },
    // 任务点击弹窗
    rowClick(row) {
      this.$refs.viewDialog.showView(row, "查看");
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-bar-container {
  position: relative;
  height: $base-nav-bar-height;
  padding-right: $base-padding;
  padding-left: $base-padding;
  overflow: hidden;
  user-select: none;
  background: $base-color-white;
  box-shadow: $base-box-shadow;

  .left-panel {
    display: flex;
    align-items: center;
    justify-items: center;
    height: $base-nav-bar-height;

    .fold-unfold {
      color: $base-color-gray;
      cursor: pointer;
      margin-right: 20px;
    }

    ::v-deep {
      .breadcrumb-container {
        /* margin-left: 10px; */
      }
    }
  }

  .right-panel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: $base-top-bar-height;

    ::v-deep {
      .user-name {
        color: $base-font-color;
      }

      .user-name + i {
        color: $base-font-color;
      }
      .el-badge {
        margin-right: 15px;
      }

      .icon-class {
        color: $base-font-color !important;
      }
    }
  }
}
.message-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
