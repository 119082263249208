<template>
    <div class="picker-content">
        <div v-for="item in images" :key="item" style="float: left">
            <el-upload
                v-if="item === 'upload'"
                class="upload-demo"
                ref="upload"
                action="https://jsonplaceholder.typicode.com/posts/"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :show-file-list="false"
                :auto-upload="true"
            >
                <div
                    class="item"
                    slot="trigger"
                    :class="{ upload: item === 'upload' }"
                >
                    <i class="el-icon-picture-outline"></i>
                </div>
            </el-upload>
            <div
                v-else-if="item.indexOf('background-2') > -1"
                @click="handleClick(item)"
                class="item"
                :class="{ 'is-active': value == 'background2' }"
            >
                <img :src="item" />
            </div>
            <div
                v-else
                @click="handleClick(item)"
                class="item"
                :class="{ 'is-active': value == 'background' }"
            >
                <img :src="item" />
            </div>
        </div>
    </div>
</template>

<script>
const defaultBackground = require("@/assets/theme/background-1.jpg");
const defaultBackground2 = require("@/assets/theme/background-2.png");
export default {
    name: "SiderBackgroundPicker",
    model: {
        prop: "value",
        event: "input",
    },
    props: {
        value: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            images: ["upload", defaultBackground, defaultBackground2],
            val: "",
        };
    },
    mounted() {
        this.val = this.value;
    },
    methods: {
        handleClick(item) {
            if (item === "upload") {
                return false;
            }
            //判断是取消还是切换
            if (
                (this.value == "background2" &&
                    item.indexOf("background-2") > -1) ||
                (this.value == "background" &&
                    item.indexOf("background-1") > -1)
            ) {
                this.$emit("input", "none");
            } else {
                if (item.indexOf("background-2") > -1) {
                    this.$emit("input", "background2");
                } else {
                    this.$emit("input", "background");
                }
            }
        },
        submitUpload() {
            this.$refs.upload.submit();
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        handlePreview(file) {
            console.log(file);
        },
    },
};
</script>

<style lang="scss" scoped>
.picker-content {
    width: calc(258px - 20px);
}
.upload-demo {
    // width: calc(258px - 20px);
    width: 100px;
    height: 100px;
    ::v-deep {
        .el-upload {
            width: 100%;
        }
    }
}

.item {
    display: block;
    float: left;
    width: 80px;
    height: 80px;
    margin: 10px;
    cursor: pointer;
    border-radius: 5px;
    img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 5px;
    }
    &.upload {
        background: #f7f7f7 none;
        text-align: center;
        /* &::before {
            content: "\e669";
            font-family: "iconfont","element-icons" !important;
            font-size: 16px;
            font-weight: 580;
            line-height: 80px;
            background: #f7f7f7 none;
            background-size: cover;
        } */
        i {
            font-size: 18px;
            line-height: 80px;
        }
    }
    &.is-active {
        box-shadow: 0 0 5px 2px #1890ff;
    }
    &.is-disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}
</style>