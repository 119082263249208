<template>
    <div class="icon-badge">
        <el-popover
            placement="bottom"
            :width="width"
            :disabled="width<=0"
            trigger="hover"
            :content="content">
            <el-badge :value="value" class="item" :max="99" slot="reference">
                <i :class="['icon-class', iconName]"  @click="click"></i>
            </el-badge>
            <slot />
        </el-popover>
    </div>
</template>

<script>
export default {
    name: 'IconBadge',
    props: {
        iconName: {
            type: String,
            default: ""
        },
        value: {
            type: [String, Number],
            default: ''
        },
        content: {
            type: String,
            default: ''
        },
        width: {
            type: [String, Number],
            default: 0
        }
    },
    methods: {
        click() {
            this.$emit('click')
        }
    }
}
</script>

<style lang="scss" scoped>
.icon-badge{
    margin-right: 20px;
    /* margin-top: 10px; */
}
.icon-class {
    font-size: 18px;
    cursor: pointer;
    color: var(--color-whitle);
}
.el-badge__content.is-fixed{
    position: absolute;
    top: 0;
    right: calc(1px + var(--el-badge-size)/2);
    transform: translateY(-50%) translateX(100%);
}
</style>