<template>
    <div v-if="showMessage" class="global-message">
      请切换到极速模式以获得更好的浏览体验！
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showMessage: false,
      };
    },
    mounted() {
      this.checkBrowserCompatibility();
    },
    methods: {
      checkBrowserCompatibility() {
        const is360CompatibleMode =
          /360\s{0,}(ee|se|chrome|firefox|opera|qq|metasr)\//i.test(navigator.userAgent);
        this.showMessage = is360CompatibleMode;
      },
    },
  };
  </script>
  
  <style>
  .global-message {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #f44336;
    color: #fff;
    text-align: center;
    padding: 10px;
  }
  </style>