<template>
    <!-- 分栏 -->
    <el-scrollbar
        class="column-bar-container"
        :class="{ 'is-collapse': collapse }"
    >
        <logo />
        <el-tabs
            tab-position="left"
            :stretch="true"
            v-model="currentTab"
            @tab-click="handleTabClick"
        >
            <template v-for="(route, index) in tabList">
                <el-tab-pane :key="index" :name="`${index}`">
                    <column-grid slot="label" :item="route" />
                </el-tab-pane>
            </template>
        </el-tabs>
        <el-menu
            class="el-menu-column"
            :default-active="activeMenu"
            :collapse="collapse"
            :collapse-transition="false"
            :default-openeds="defaultOopeneds"
            :unique-opened="uniqueOpened"
            mode="vertical"
        >
            <el-divider content-position="center">{{ activeTitle }}</el-divider>
            <template v-for="route in routeChildren">
                <side-bar-item
                    :key="route.path"
                    :full-path="handlePath(route.path)"
                    :item="route"
                ></side-bar-item>
            </template>
        </el-menu>
    </el-scrollbar>
</template>

<script>
import { mapGetters } from "vuex";
import { defaultOopeneds, uniqueOpened } from "@/config";
import { isExternal } from "@/utils/validate";
import path from "path";
export default {
    name: "ColumnBar",
    data() {
        return {
            currentTab: "0",
            defaultOopeneds,
            uniqueOpened,
            mainRouteId: "",
            currentRoute: undefined,
            rootPath: "/",
            routeTopId: "",
            rootRoute: undefined,
            is_one: true,
        };
    },
    computed: {
        ...mapGetters({
            collapse: "settings/collapse",
            routes: "routes/routes",
            mainRoutes: "routes/mainRoutes",
        }),
        activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            return path;
        },
        activeTitle() {
            const { tabList, currentTab } = this;
            if (this.rootRoute && this.rootRoute.meta) {
                return this.rootRoute.meta.title;
            }
            return tabList[currentTab] && tabList[currentTab].meta
                ? tabList[currentTab].meta.title
                : "";
        },
        routeChildren() {
            if (
                this.rootRoute &&
                this.rootRoute.children &&
                this.rootRoute.children.length > 0
            ) {
                return this.rootRoute.children;
            } else {
                let currentRouter = this.tabList[this.currentTab];
                let children =
                    currentRouter && currentRouter.children
                        ? currentRouter.children
                        : [];
                this.rootRoute = this.tabList[this.currentTab];

                return children;
            }
            // return this.rootRoute && this.rootRoute.children && this.rootRoute.children.length > 0 ? this.rootRoute.children : []
        },
        tabList() {
            let arr = [];
            arr =
                (this.mainRoutes &&
                    this.mainRoutes.filter((item) => !item.hidden)) ||
                [];
            return arr;
        },
    },
    watch: {
        $route() {
            this.currentRoute = undefined;
            this.rootRoute = undefined;
            this.rootPath = "/";
            this.routeTopId = 0;
            const { path } = this.$route;
            if (path) {
                this.initRoute(path);
            }
        },
        mainRoutes() {
            if (!this.rootRoute) {
                this.currentTab = this.getMainActiveTab();
            }
        },
    },
    created() {
        const { path } = this.$route;
        if (path) {
            this.initRoute(path);
            setTimeout(() => {
                if (!this.rootRoute) {
                    this.currentTab = this.getMainActiveTab();
                }
            }, 0);
        }
    },
    methods: {
        getMainActiveTab() {
            let route = this.$route;
            let { tabList } = this;
            for (let r in tabList) {
                let proutes = tabList[r];
                if (proutes.children) {
                    let flag = 0;
                    const findChildrenDeep = (croutes, resolvePath) => {
                        if (route.path == resolvePath) {
                            flag = 1;
                            return 1;
                        }
                        if (!croutes || croutes.length <= 0) {
                            return "-1";
                        }
                        for (let c of croutes) {
                            findChildrenDeep(
                                c.children,
                                path.resolve(resolvePath, c.path)
                            );
                        }
                    };
                    findChildrenDeep(proutes.children, proutes.path);
                    if (flag == 1) {
                        return `${r}`;
                    }
                } else {
                    if (route.path == path.resolve(proutes.path, "")) {
                        return `${r}`;
                    }
                }
            }
            return "0";
        },
        initRoute(path) {
            for (let key in this.routes) {
                let route = this.routes[key];
                if (route.children) {
                    this.findRoutePath(route, path, route.children, route.path);
                    if (this.currentRoute) {
                        break;
                    }
                }
            }
        },
        findRoutePath(rootRoute, route_path, routes, basePath = "/") {
            for (let key in routes) {
                let route = routes[key];
                if (route.menu_type == "1") {
                    const tagPath = path.resolve(basePath, route.path);
                    if (tagPath == route_path) {
                        this.$store.dispatch("routes/setCurrentRoute", {
                            current: this.currentRoute,
                            root: this.rootRoute,
                        });
                        this.currentRoute = route;
                        this.rootRoute = rootRoute;
                        this.routeTopId = rootRoute.id;
                        this.currentTab = this.getActiveTab();

                        return true;
                    }
                }
                if (route.children) {
                    this.findRoutePath(
                        rootRoute,
                        route_path,
                        route.children,
                        route.path
                    );
                }
            }
        },
        getActiveTab() {
            let { tabList } = this;
            let is_exist = false;
            let is_app = -1;
            for (let r in tabList) {
                let proutes = tabList[r];
                if (proutes.id == this.routeTopId) {
                    is_exist = true;
                    return `${r}`;
                }
                if (proutes.path == "/app") {
                    is_app = `${r}`;
                }
            }
            if (!is_exist) {
                return is_app;
            }
            return this.currentTab;
        },
        handlePath(routePath) {
            if (isExternal(routePath)) {
                return routePath;
            }
            if (isExternal(this.fullPath)) {
                return this.fullPath;
            }
            let parentRoute = this.rootRoute.path;
            return path.resolve(parentRoute, routePath);
        },
        handleTabClick(tab) {
            const route = this.tabList[this.currentTab];
            const routePath = route.path;
            //深度获取第一层子路由的路径
            const getChildPathDeep = (childrenRoutes, resolvePath) => {
                if (childrenRoutes && childrenRoutes.length > 0) {
                    return getChildPathDeep(
                        childrenRoutes[0].children,
                        path.resolve(resolvePath, childrenRoutes[0].path)
                    );
                } else {
                    return resolvePath;
                }
            };
            const childrenRoute = getChildPathDeep(route.children, routePath); //route.children && route.children[0] && route.children[0].path || ""

            const target = route.meta.target;

            if (target == "_blank") {
                console.log(
                    routePath,
                    path.resolve(routePath, childrenRoute),
                    "handleTabClick"
                );
                if (isExternal(routePath)) {
                    window.open(routePath);
                } else if (isExternal(this.fullPath)) {
                    window.open(this.fullPath);
                } else if (
                    this.$route.path !== path.resolve(routePath, childrenRoute)
                ) {
                    let routeData = this.$router.resolve(
                        path.resolve(routePath, childrenRoute)
                    );
                    window.open(routeData.href);
                }
            } else {
                if (isExternal(routePath)) {
                    window.location.href = routePath;
                } else if (isExternal(this.fullPath)) {
                    window.location.href = this.fullPath;
                } else if (
                    this.$route.path !== path.resolve(routePath, childrenRoute)
                ) {
                    this.$router.push(path.resolve(routePath, childrenRoute));
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@mixin active {
    &.is-active {
        background-color: var(--color-primary);

        .column-grid {
            /* background-color: var(--color-primary); */
            color: #fff;

            i {
                color: #fff;
            }
        }
    }
}

@mixin menu-active {
    &:hover,
    &.is-active {
        background-color: var(--column-menu-background);
        color: var(--color-primary);

        i {
            color: var(--color-primary);
        }
    }
}

.column-bar-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: $base-z-index;
    width: $base-left-menu-width;
    height: 100vh;
    overflow: hidden;
    box-shadow: 0 1px 6px rgb(0 21 41 / 8%);
    transition: width $base-transition-time;
    background-color: #fff;

    &.is-collapse {
        width: $base-left-column-width;

        ::v-deep {
            .title {
                width: 0;
                display: none;
            }
        }
    }

    ::v-deep {
        .el-tabs {
            position: fixed;
            box-shadow: 0 1px 4px rgb(0 21 41 / 9%);
            background: var(--menu-background);
            color: var(--menu-color);

            .el-tabs__nav {
                height: calc(100vh - #{$base-left-column-height});
            }
        }

        .el-scrollbar__wrap {
            overflow-x: hidden;
        }

        .el-tabs--left {
            .el-tabs__nav-wrap {
                &.is-left {
                    margin-right: 0 !important;
                }

                &.is-left::after {
                    width: 0;
                    background: #dcdfe6;
                }
            }

            .el-tabs__header {
                &.is-left {
                    margin-right: 0 !important;
                }

                .el-tabs__nav-scroll {
                    height: 100%;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        width: 0;
                        height: 0;
                        background: transparent;
                    }
                }
            }
        }

        .el-tabs__active-bar.is-left {
            display: none;
        }

        .el-tabs__item {
            box-sizing: border-box;
            padding: 5px;
            height: auto !important;
            display: block;
            @include active;

            .column-grid {
                text-align: center;
                width: calc(#{$base-left-column-width} - 10px) !important;
                height: calc(#{$base-left-column-width} - 10px) !important;
                border-radius: 5px;
                color: var(--menu-color);
                padding-top: 10px;

                i {
                    display: block;
                    color: var(--menu-color);
                    height: 20px;
                    line-height: 20px;
                }

                span {
                    margin: 0;
                    padding: 0;
                    display: block;
                    line-height: 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    vertical-align: middle;
                    // color: $base-color-black;
                }

                .svg-icon-w {
                    margin-right: 0;
                    display: block;
                    text-align: center;
                    // color: var(--menu-color);
                    width: 100%;
                    height: 20px;
                    line-height: 20px;
                }

                .fa {
                    display: block;
                    margin-right: 0;
                    text-align: center;
                }
            }
        }

        .el-menu {
            .el-divider__text {
                color: $base-color-black;
                width: 100px;
                text-align: center;
                padding: 0;
            }
        }

        .el-menu-column {
            left: calc(#{$base-left-column-width} + 4px);
            width: calc(
                #{$base-left-menu-width} - #{$base-left-column-width} - 6px
            );
            border: 0;
        }

        .el-menu-item,
        .el-submenu__title {
            height: 50px;
            overflow: hidden;
            line-height: 50px;
            text-overflow: ellipsis;
            white-space: nowrap;
            vertical-align: middle;
            color: $base-color-black;

            i {
                color: $base-color-black;
                padding-right: 3px;
            }
        }

        .el-menu-item,
        .el-submenu__title {
            @include menu-active;
        }
    }
}
</style>